import { useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ConfirmProvider } from 'material-ui-confirm'

import { Header } from './pages/layout/Header'
import { Menu } from './pages/layout/Menu'
import { Company } from './pages/Company/Company'
import { Account } from './pages/Account/Account'
import { Orders } from './pages/Orders/Orders'
import { PairedView } from './pages/PairedView/PairedView'
import { Targets } from './pages/Targets/Targets'

import { useCompanyContext } from './hooks/context/CompanyContext'
import { useOrdersContext } from './hooks/context/OrdersContext'
import { useContactContext } from './hooks/context/ContactContext'

import { Sectors } from './types/Sectors'
import { BusinessModels } from './types/BusinessModels'
import { Locations } from './types/Locations'
import { Profiles } from './types/Profiles'

function App() {
  const [isLoading, setIsLoading] = useState(true)
  const { companies, dispatchCompanyContext } = useCompanyContext()
  const {
    buyOrders,
    sellOrders,
    holdings,
    targets,
    pairOrders,
    dispatchOrdersContext,
  } = useOrdersContext()
  const { contacts, dispatchContactContext } = useContactContext()

  useEffect(() => {
    // LOAD DATA
    const companiesData = localStorage.getItem('companies')
    if (companiesData) {
      const companies = JSON.parse(companiesData)
      for (const company of companies) {
        if (typeof company?.businessModel === 'string') {
          company.businessModel = [company.businessModel]
        } else if (!company?.businessModel) {
          company.businessModel = []
        }

        company.sectors =
          company.sectors?.filter((elem: any) => Sectors.includes(elem)) ?? []
        company.dealType = Array.isArray(company.dealType)
          ? company.dealType
          : company.dealType?.length
          ? [company.dealType]
          : []
        company.location = Locations.includes(company.location)
          ? company.location
          : ''
        company.businessModel =
          company.businessModel?.filter((elem: any) =>
            BusinessModels.includes(elem)
          ) ?? []

        company.industryVertical = Array.isArray(company.industryVertical)
          ? company.industryVertical
          : []

        company.investmentStage = Array.isArray(company.investmentStage)
          ? ''
          : company.investmentStage
        company.valuationRange = Array.isArray(company.valuationRange)
          ? ''
          : company.valuationRange
      }
      dispatchCompanyContext({ companies })
    }
    const buyOrdersData = localStorage.getItem('buyOrders')
    if (buyOrdersData) {
      dispatchOrdersContext({ buyOrders: JSON.parse(buyOrdersData) })
    }
    const sellOrdersData = localStorage.getItem('sellOrders')
    if (sellOrdersData) {
      dispatchOrdersContext({ sellOrders: JSON.parse(sellOrdersData) })
    }
    const holdingsData = localStorage.getItem('holdings')
    if (holdingsData) {
      dispatchOrdersContext({ holdings: JSON.parse(holdingsData) })
    }
    const targetsData = localStorage.getItem('targets')
    if (targetsData) {
      dispatchOrdersContext({ targets: JSON.parse(targetsData) })
    }
    const contactsData = localStorage.getItem('contacts')
    if (contactsData) {
      const contacts = JSON.parse(contactsData)

      for (const contact of contacts) {
        contact.location = Locations.includes(contact.location)
          ? contact.location
          : ''
        contact.profile =
          contact.profile?.filter((elem: any) => Profiles.includes(elem)) ?? []
        contact.sectors =
          contact.sectors?.filter((elem: any) => Sectors.includes(elem)) ?? []
      }
      dispatchContactContext({ contacts })
    }
    const pairOrdersData = localStorage.getItem('pairOrders')
    if (pairOrdersData) {
      dispatchOrdersContext({ pairOrders: JSON.parse(pairOrdersData) })
    }
    setIsLoading(false)
  }, [])

  useEffect(() => {
    if (!isLoading) {
      localStorage.removeItem('companies')
      localStorage.removeItem('buyOrders')
      localStorage.removeItem('sellOrders')
      localStorage.removeItem('holdings')
      localStorage.removeItem('targets')
      localStorage.removeItem('contacts')
      localStorage.removeItem('pairOrders')
      localStorage.setItem('companies', JSON.stringify(companies))
      localStorage.setItem('buyOrders', JSON.stringify(buyOrders))
      localStorage.setItem('sellOrders', JSON.stringify(sellOrders))
      localStorage.setItem('holdings', JSON.stringify(holdings))
      localStorage.setItem('targets', JSON.stringify(targets))
      localStorage.setItem('contacts', JSON.stringify(contacts))
      localStorage.setItem('pairOrders', JSON.stringify(pairOrders))
    }
  }, [
    companies,
    buyOrders,
    sellOrders,
    holdings,
    targets,
    contacts,
    pairOrders,
  ])

  return (
    <ConfirmProvider
      defaultOptions={{
        confirmationButtonProps: { autoFocus: true },
      }}
    >
      <BrowserRouter>
        <Header />
        <Menu />
        <main>
          <Routes>
            <Route path="/" element={<Company />} />
            <Route path="/company" element={<Company />} />
            <Route path="/account" element={<Account />} />
            <Route path="/paired" element={<PairedView />} />
            <Route
              path="/buy-orders"
              element={<Orders orders={buyOrders} title="Buy Orders" />}
            />
            <Route
              path="/sell-orders"
              element={<Orders orders={sellOrders} title="Sell Orders" />}
            />
            <Route
              path="/targets"
              element={<Targets orders={targets} title="Acquistion Targets" />}
            />
            <Route
              path="/holdings"
              element={<Targets orders={holdings} title="Current Holdings" />}
            />
          </Routes>
        </main>
      </BrowserRouter>
    </ConfirmProvider>
  )
}

export default App
