import { useState, useEffect } from 'react'
import {
  Autocomplete,
  Modal,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

import { useOrdersContext } from '../../hooks/context/OrdersContext'
import { useContactContext } from '../../hooks/context/ContactContext'

import { currencies } from '../../data/currencies'
import { findContactById } from '../../utils/findContact'
import { findOrderById } from '../../utils/findOrder'
import { sortByName } from '../../utils/sortByValuation'

import { Order } from '../../types/Order'

import './ModalOrder.css'

type ModalOrderProps = {
  open: boolean
  setOpen: any
  orderId?: string
  type: 'Sell' | 'Buy'
  onConfirm: any
  onDelete: any
}

const initialState: Order = {
  company: '',
  contact: '',
  category: '',
  estSize: {
    value: '',
    currency: '',
  },
  pricePerShare: {
    value: '',
    currency: '',
  },
  valuation: {
    value: '',
    currency: '',
  },
  shareClass: '',
  structure: '',
  feeStructure: '',
  comment: '',
}

export const ModalOrder = ({
  open,
  setOpen,
  orderId,
  type,
  onConfirm,
  onDelete,
}: ModalOrderProps) => {
  const { contacts } = useContactContext()
  const { buyOrders, sellOrders } = useOrdersContext()
  const [orderValues, setOrderValues] = useState<Order>(initialState)

  useEffect(() => {
    if (open) {
      if (orderId) {
        const foundOrder = findOrderById(orderId, [...buyOrders, ...sellOrders])
        if (foundOrder) {
          setOrderValues({ ...foundOrder })
        } else {
          setOpen(false)
        }
      } else {
        setOrderValues(initialState)
      }
    }
  }, [open, orderId])

  const isButtonDisabled = !orderValues?.contact?.length

  // AVOID ERRORS
  if (!open || orderId === '') {
    return null
  }

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <div className="order-main-div">
        {orderId ? <h3>Edit {type} Order</h3> : <h3>Create {type} Order</h3>}
        <div className="form">
          <div className="input-row">
            <Autocomplete
              onChange={(event, values) =>
                setOrderValues((prev) => ({
                  ...prev,
                  contact: values?.id ?? null,
                }))
              }
              options={contacts.sort(sortByName)}
              value={findContactById(orderValues.contact, contacts) ?? null}
              getOptionLabel={(option) => option.name}
              fullWidth
              renderInput={(params) => (
                <TextField {...params} label="Contact" variant="outlined" />
              )}
            />
            <FormControl fullWidth>
              <InputLabel id="category-label">Category</InputLabel>
              <Select
                value={orderValues.category}
                onChange={(event) =>
                  setOrderValues((prev) => ({
                    ...prev,
                    category: event.target.value,
                  }))
                }
                variant="outlined"
                labelId="category-label"
                label="Category"
              >
                <MenuItem value={'VC'}>VC</MenuItem>
                <MenuItem value={'PE'}>PE</MenuItem>
                <MenuItem value={'FO'}>FO</MenuItem>
                <MenuItem value={'Corporation'}>Corporation</MenuItem>
                <MenuItem value={'Individual'}>Individual</MenuItem>
                <MenuItem value={'Other'}>Other</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="currency-row">
            <TextField
              className="value"
              value={orderValues.pricePerShare.value}
              onChange={(event) =>
                setOrderValues((prev) => ({
                  ...prev,
                  pricePerShare: {
                    ...prev.pricePerShare,
                    value: event.target.value,
                  },
                }))
              }
              label="Price Per Share"
              variant="outlined"
            />
            <Select
              className="currency"
              value={orderValues.pricePerShare.currency}
              onChange={(event) =>
                setOrderValues((prev) => ({
                  ...prev,
                  pricePerShare: {
                    ...prev.pricePerShare,
                    currency: event.target.value,
                  },
                }))
              }
              variant="outlined"
            >
              {Object.keys(currencies).map((elem) => (
                <MenuItem key={`share-${elem}`} value={elem}>
                  {elem}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="currency-row">
            <TextField
              className="value"
              value={orderValues.valuation.value}
              onChange={(event) =>
                setOrderValues((prev) => ({
                  ...prev,
                  valuation: { ...prev.valuation, value: event.target.value },
                }))
              }
              label="Valuation (mn)"
              variant="outlined"
            />
            <Select
              className="currency"
              value={orderValues.valuation.currency}
              onChange={(event) =>
                setOrderValues((prev) => ({
                  ...prev,
                  valuation: {
                    ...prev.valuation,
                    currency: event.target.value,
                  },
                }))
              }
              variant="outlined"
            >
              {Object.keys(currencies).map((elem) => (
                <MenuItem key={`valuation-${elem}`} value={elem}>
                  {elem}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="currency-row">
            <TextField
              className="value"
              value={orderValues.estSize.value}
              onChange={(event) =>
                setOrderValues((prev) => ({
                  ...prev,
                  estSize: { ...prev.estSize, value: event.target.value },
                }))
              }
              label="Est Size (,000)"
              variant="outlined"
            />
            <Select
              className="currency"
              value={orderValues.estSize.currency}
              onChange={(event) =>
                setOrderValues((prev) => ({
                  ...prev,
                  estSize: { ...prev.estSize, currency: event.target.value },
                }))
              }
              variant="outlined"
            >
              {Object.keys(currencies).map((elem) => (
                <MenuItem key={`size-${elem}`} value={elem}>
                  {elem}
                </MenuItem>
              ))}
            </Select>
          </div>
          <FormControl fullWidth>
            <InputLabel id="share-class-label">Share Class</InputLabel>
            <Select
              value={orderValues.shareClass}
              onChange={(event) =>
                setOrderValues((prev) => ({
                  ...prev,
                  shareClass: event.target.value,
                }))
              }
              variant="outlined"
              labelId="share-class-label"
              label="Share Class"
              fullWidth
            >
              <MenuItem value={'Common'}>Common</MenuItem>
              <MenuItem value={'Preferred'}>Preferred</MenuItem>
              <MenuItem value={'Ordinary'}>Ordinary</MenuItem>
              <MenuItem value={'Does not matter'}>Does not matter</MenuItem>
              <MenuItem value={'Other'}>Other</MenuItem>
            </Select>
          </FormControl>
          <div className="input-row">
            <FormControl fullWidth>
              <InputLabel id="structure-label">Structure</InputLabel>
              <Select
                value={orderValues.structure}
                onChange={(event) =>
                  setOrderValues((prev) => ({
                    ...prev,
                    structure: event.target.value,
                  }))
                }
                label="Structure"
                labelId="structure-label"
                variant="outlined"
              >
                <MenuItem value={'SPV'}>SPV</MenuItem>
                <MenuItem value={'Direct Share'}>Direct Share</MenuItem>
                <MenuItem value={'Forward'}>Forward</MenuItem>
                <MenuItem value={'ESOP'}>ESOP</MenuItem>
                <MenuItem value={'Does not matter'}>Does not matter</MenuItem>
                <MenuItem value={'Other'}>Other</MenuItem>
              </Select>
            </FormControl>
            <TextField
              value={orderValues.feeStructure}
              onChange={(event) =>
                setOrderValues((prev) => ({
                  ...prev,
                  feeStructure: event.target.value,
                }))
              }
              label="Fee Structure"
              variant="outlined"
              fullWidth
            />
          </div>
          <TextField
            value={orderValues.comment}
            onChange={(event) =>
              setOrderValues((prev) => ({
                ...prev,
                comment: event.target.value,
              }))
            }
            label="Comments"
            variant="outlined"
            multiline
            rows={3}
            fullWidth
          />
        </div>
        <div className="order-buttons-div">
          <button
            disabled={isButtonDisabled}
            onClick={() =>
              onConfirm({
                ...orderValues,
                estSize: {
                  ...orderValues.estSize,
                  value: isNaN(parseFloat(orderValues.estSize.value as string))
                    ? '-'
                    : parseFloat(orderValues.estSize.value as string).toFixed(
                        2
                      ),
                },
                valuation: {
                  ...orderValues.valuation,
                  value: isNaN(
                    parseFloat(orderValues.valuation.value as string)
                  )
                    ? '-'
                    : parseFloat(orderValues.valuation.value as string).toFixed(
                        2
                      ),
                },
                pricePerShare: {
                  ...orderValues.pricePerShare,
                  value: isNaN(
                    parseFloat(orderValues.pricePerShare.value as string)
                  )
                    ? '-'
                    : parseFloat(
                        orderValues.pricePerShare.value as string
                      ).toFixed(2),
                },
              })
            }
          >
            <CheckCircleOutlineIcon className="confirm-icon" />
          </button>
          <button onClick={() => onDelete(orderValues)}>
            <DeleteOutlineIcon className="delete-icon" />
          </button>
        </div>
      </div>
    </Modal>
  )
}
