export const findContactByName = (
  name: string,
  contacts: any[]
): any | null => {
  return contacts.find(
    (elem) => elem.name.toLowerCase() === name.trim().toLowerCase()
  )
}

export const findContactById = (id: string, contacts: any[]): any | null => {
  return contacts.find((elem) => elem.id === id)
}
