export const ValuationRanges = [
  '< $5m',
  '$5 to $20m',
  '$21 to 100m',
  '$101 to $500m',
  '$501 to $1 bn',
  '$1.1 bn to $10 bn',
  '>$10 bn',
  '-',
]
