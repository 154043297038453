export const removeFromArrayById = (id: string, array: any[]) => {
  const newArray = [...array]
  const index = newArray.findIndex((elem) => elem.id === id)
  if (index > -1) {
    newArray.splice(index, 1)
  }
  return newArray
}

export const removeFromPairOrders = (id: string, array: any[]) => {
  const newArray = [...array]
  const t = true
  while (t) {
    const index = newArray.findIndex(
      (elem) => elem.sellOrder === id || elem.buyOrder === id
    )
    if (index > -1) {
      newArray.splice(index, 1)
    } else {
      break
    }
  }
  return newArray
}

export const removeCompanyFromPairOrders = (name: string, array: any[]) => {
  const newArray = [...array]
  const t = true
  while (t) {
    const index = newArray.findIndex((elem) => elem.id.includes(name))
    if (index > -1) {
      newArray.splice(index, 1)
    } else {
      break
    }
  }
  return newArray
}

export const removeCompanyFromOrders = (id: string, array: any[]) => {
  const newArray = [...array]
  const t = true
  while (t) {
    const index = newArray.findIndex((elem) => elem.company === id)
    if (index > -1) {
      newArray.splice(index, 1)
    } else {
      break
    }
  }
  return newArray
}

export const removeContactFromOrders = (
  id: string,
  orders: any[],
  pairOrders: any[]
) => {
  const newOrders = [...orders]
  let newPairOrders = [...pairOrders]
  const t = true
  while (t) {
    const index = newOrders.findIndex((elem) => elem.contact === id)
    if (index > -1) {
      const id = newOrders[index].id
      newPairOrders = removeFromPairOrders(id, newPairOrders)
      newOrders.splice(index, 1)
    } else {
      break
    }
  }
  return { newOrders, newPairOrders }
}

export const removeContactFromTargtes = (id: string, array: any[]) => {
  const newArray = [...array]
  const t = true
  while (t) {
    const index = newArray.findIndex((elem) => elem.contact === id)
    if (index > -1) {
      newArray.splice(index, 1)
    } else {
      break
    }
  }
  return newArray
}
