export const checkIfAlreadyPaired = (
  selectedOrder: string,
  orderId: string,
  list: any[]
) => {
  for (const pair of list) {
    if (
      (pair.sellOrder === selectedOrder && pair.buyOrder === orderId) ||
      (pair.buyOrder === selectedOrder && pair.sellOrder === orderId)
    ) {
      return true
    }
  }
  return false
}
