export const Locations = [
  'San Francisco / Silicon Valley',
  'New York City',
  'Austin, TX',
  'Boston',
  'Los Angeles',
  'London',
  'Hong Kong',
  'Singapore',
  'Dubai',
  'Americas - US',
  'Americas - Canada',
  'Americas - Mexico',
  'Americas - Brazil',
  'Americas - Latin America',
  'Europe - UK',
  'Europe - France',
  'Europe - Germany',
  'Europe - Italy',
  'Europe - Spain',
  'Europe - Portugal',
  'Europe - Nordic countries',
  'Europe - Other EU block',
  'Europe - Other non EU block',
  'Asia - China',
  'Asia - India',
  'Asia - Japan',
  'Asia - Korea',
  'Asia - Taiwan',
  'Asia - SE Asia',
  'Asia - Other',
  'MENA - Israel',
  'MENA - Africa',
  'MENA - Other',
  '-',
]
