export const sortByValuation = (a: any, b: any) => {
  if (a.valuation.value < b.valuation.value) {
    return 1
  }
  if (a.valuation.value > b.valuation.value) {
    return -1
  }
  return 0
}

export const sortByCompany = (a: any, b: any) => {
  if (a.company > b.company) {
    return 1
  }
  if (a.company < b.company) {
    return -1
  }
  return 0
}

export const sortById = (a: any, b: any) => {
  if (a.id < b.id) {
    return -1
  }
  if (a.id > b.id) {
    return 1
  }
  return 0
}

export const sortByName = (a: any, b: any) => {
  if (a.name?.toLowerCase() < b.name?.toLowerCase()) {
    return -1
  }
  if (a.name?.toLowerCase() > b.name?.toLowerCase()) {
    return 1
  }
  return 0
}

export const sortByFirmName = (a: any, b: any) => {
  const bFirmName = b.firmName ?? ''
  const aFirmName = a.firmName ?? ''
  if (aFirmName?.toLowerCase() < bFirmName?.toLowerCase()) {
    return -1
  }
  if (aFirmName?.toLowerCase() > bFirmName?.toLowerCase()) {
    return 1
  }
  return 0
}
