import './TableTitle.css'

export const TableTitle = ({
  title,
  buttonText,
  onPress,
}: {
  title: string
  buttonText?: string
  onPress?: any
}) => {
  return (
    <div className="table-title-main-div">
      <h3>{title}</h3>
      {buttonText && <button onClick={onPress}>{buttonText}</button>}
    </div>
  )
}
