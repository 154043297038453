export const InvestmentStages = [
  'Pre-seed / seed',
  'Series A',
  'Series B',
  'Series C',
  'Series D and beyond',
  'Post-unicorn status',
  'Pre-IPO (within 18 mths; or IB engaged)',
  '-',
]
