import { useState, useEffect } from 'react'
import { Modal, Checkbox, TextField } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'

import { useOrdersContext } from '../../hooks/context/OrdersContext'
import { useContactContext } from '../../hooks/context/ContactContext'
import { useCompanyContext } from '../../hooks/context/CompanyContext'

import { findOrderById } from '../../utils/findOrder'
import { findContactById } from '../../utils/findContact'
import { findCompanyById } from '../../utils/findCompany'
import { sortByValuation } from '../../utils/sortByValuation'
import { checkIfAlreadyPaired } from '../../utils/findPairOrder'

import './CreatePairOrder.css'

type CreatePairOrderProps = {
  open: boolean
  setOpen: any
  orderId: string
}

export const CreatePaiorOrder = ({
  open,
  setOpen,
  orderId,
}: CreatePairOrderProps) => {
  const [orderChecked, setOrderChecked] = useState('')
  const [comment, setComment] = useState('')
  const { buyOrders, sellOrders, pairOrders, dispatchOrdersContext } =
    useOrdersContext()
  const { contacts } = useContactContext()
  const { companies } = useCompanyContext()

  useEffect(() => {
    if (open) {
      setOrderChecked('')
    }
  }, [open])

  // AVOID ERRORS
  if (!open || orderId === '') {
    return null
  }

  const selectedOrder = findOrderById(orderId, [...buyOrders, ...sellOrders])
  const orderType = selectedOrder.id.includes('S') ? 'Sell' : 'Buy'

  const createPairOrder = () => {
    if (orderType === 'Sell') {
      dispatchOrdersContext({
        pairOrders: [
          ...pairOrders,
          {
            id: `${
              findCompanyById(selectedOrder.company, companies).name
            } - ${orderChecked} - ${orderId}`,
            buyOrder: orderChecked,
            sellOrder: orderId,
            comment,
          },
        ],
      })
    } else if (orderType === 'Buy') {
      dispatchOrdersContext({
        pairOrders: [
          ...pairOrders,
          {
            id: `${
              findCompanyById(selectedOrder.company, companies).name
            } - ${orderId} - ${orderChecked}`,
            buyOrder: orderId,
            sellOrder: orderChecked,
            comment,
          },
        ],
      })
    }
    setOpen(false)
  }

  const pairOrderColumns = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: false,
      flex: 1,
      renderCell: (param: any) => (
        <div>
          <Checkbox
            checked={param.value === orderChecked}
            onChange={() => setOrderChecked(param.value)}
          />
          <b style={{ color: '#707FDD' }}>{param.value}</b>
        </div>
      ),
    },
    {
      field: 'contact',
      headerName: 'Contact',
      sortable: false,
      flex: 1,
    },
    {
      field: 'estSize',
      headerName: 'Est Size (,000)',
      sortable: false,
      valueFormatter: (v: any) =>
        `${v.value?.currency.toString() ?? '-'} ${
          v.value?.value?.toString() ?? '--'
        }`,
      flex: 1,
    },
    {
      field: 'pricePerShare',
      headerName: 'Price Per Share',
      sortable: false,
      valueFormatter: (v: any) =>
        `${v.value?.currency.toString() ?? '-'} ${
          v.value?.value?.toString() ?? '--'
        }`,
      flex: 1,
    },
    {
      field: 'valuation',
      headerName: 'Valuation (mn)',
      sortable: false,
      valueFormatter: (v: any) =>
        `${v.value?.currency.toString() ?? '-'} ${
          v.value?.value?.toString() ?? '--'
        }`,
      flex: 1,
    },
    {
      field: 'comment',
      headerName: 'Comment',
      sortable: false,
      flex: 1,
      hide: true,
    },
  ]

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <div className="pair-main-div">
        <h3>Pair W/ {orderType === 'Sell' ? 'Buy' : 'Sell'} Order</h3>
        <DataGrid
          rows={
            orderType === 'Sell'
              ? buyOrders
                  .filter((order) => order.company === selectedOrder.company)
                  .filter(
                    (order) =>
                      !checkIfAlreadyPaired(orderId, order.id, pairOrders)
                  )
                  .map((elem) => ({
                    ...elem,
                    contact: findContactById(elem.contact, contacts).name,
                  }))
                  .sort(sortByValuation)
              : sellOrders
                  .filter((order) => order.company === selectedOrder.company)
                  .filter(
                    (order) =>
                      !checkIfAlreadyPaired(orderId, order.id, pairOrders)
                  )
                  .map((elem) => ({
                    ...elem,
                    contact: findContactById(elem.contact, contacts).name,
                  }))
                  .sort(sortByValuation)
                  .reverse()
          }
          columns={pairOrderColumns}
          disableSelectionOnClick
          autoPageSize
        />
        <TextField
          value={comment}
          onChange={(event) => setComment(event.target.value)}
          label="Comments"
          variant="outlined"
          multiline
          rows={3}
          fullWidth
        />
        <div className="buttons-div">
          <button
            disabled={orderChecked === ''}
            onClick={() => createPairOrder()}
          >
            <CheckCircleOutlineIcon className="confirm-icon" />
          </button>
        </div>
      </div>
    </Modal>
  )
}
