export const Sectors = [
  'AI / machine learning',
  'Adtech / advertising / marketing',
  'Agtech',
  'Analytics / big data',
  'Audiotech',
  'Biotech / bioscience',
  'Cannabis',
  'Crypto / blockchain / NFT',
  'Climate / Sustainability / Clean / Carbon tech',
  'Cloudtech & DevOps',
  'Consumer software / application',
  'Consumer electronics',
  'Data management / storage',
  'Deeptech / New frontier',
  'eSport',
  'Edutech',
  'E-commerce',
  'Enterprise software',
  'ESG',
  'Games / AR / VR / metaverse',
  'Fintech',
  'Foodtech',
  'Healthtech / digital health',
  'Hosting / development',
  'HR tech',
  'Industrial application',
  'Info security / cybersecurity',
  'Insurtech',
  'IoT',
  'Media / entertainment',
  'Medical device',
  'Nanotechnology',
  'New mobility / autonomous tech',
  'Proptech / construction related',
  'Reg tech',
  'Retail tech',
  'Ride sharing',
  'Robotics / drones',
  'Semiconductor',
  'Social media',
  'Space tech',
  'Supply chain tech',
  '3D printing',
  'Others',
]
