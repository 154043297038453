export const IndustryVerticals = [
  'Accounting & auditing',
  'Advertising',
  'Agriculture',
  'Airline',
  'Amusement & recreation',
  'Apparel & textile',
  'Arts & culture',
  'Automotive',
  'Banking & finance',
  'Business services',
  'Chemical industry',
  'Computing / network',
  'Construction',
  'Consumer discretionary',
  'Consumer services',
  'Consumer staples',
  'Creatives',
  'Defense',
  'Ecommerce',
  'Education',
  'Electricals & electronics',
  'Engineering',
  'Entertainment',
  'Environment',
  'Energy / Oil & Gas',
  'Fashion',
  'Food & beverage',
  'Forestry',
  'Gaming',
  'Government',
  'Healthcare',
  'Heavy industry',
  'Hotel / hospitality',
  'Information technology',
  'Infrastructure',
  'Insurance',
  'Law',
  'Life sciences / pharmaceuticals',
  'Logistics / distribution',
  'Luxuries',
  'Manufacturing',
  'Media',
  'Mining / commodities',
  'Non-profits',
  'Professional services',
  'Purchasing and merchandising',
  'Publishing',
  'Real estate',
  'Restaurants',
  'Retail',
  'Semiconductor',
  'Space',
  'Telecommunication',
  'Transportation',
  'Tourism / travel',
  'Utilities',
  'Wholesale',
  'Others',
]
