import { useState, useEffect } from 'react'

import ClearIcon from '@mui/icons-material/Clear'

import {
  IconButton,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Modal,
} from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'

import { Profiles } from '../../types/Profiles'
import { Sectors } from '../../types/Sectors'
import { Locations } from '../../types/Locations'
import { BusinessModels } from '../../types/BusinessModels'
import { DealTypes } from '../../types/DealTypes'
import { GeoCoverages } from '../../types/GeoCoverage'
import { IndustryVerticals } from '../../types/IndustryVertical'
import { InvestmentStages } from '../../types/InvestmentStage'
import { ValuationRanges } from '../../types/ValuationRange'

import {
  useFilterContactContext,
  useFilterCompanyContext,
} from '../../hooks/context/FiltersContext'
import { useContactContext } from '../../hooks/context/ContactContext'

import { sortByFirmName } from '../../utils/sortByValuation'

import './FiltersModal.css'

export const ContactFiltersModal = ({
  open,
  setOpen,
}: {
  open: boolean
  setOpen: any
}) => {
  const {
    dispatchFilterContext,
    location: locationFilter,
    firmName: firmNameFilter,
    stageInvestment: stageInvestmentFilter,
    sectors: sectorsFilter,
    profile: profilesFilter,
    dealType: dealTypeFilter,
    businessModel: businessModelFilter,
    geoCoverage: geoCoverageFilter,
    industryVertical: industryVerticalFilter,
    investmentStage: investmentStageFilter,
    valuationRange: valuationRangeFilter,
  } = useFilterContactContext()
  const { contacts } = useContactContext()
  const [firmName, setFirmName] = useState<string[]>(firmNameFilter)
  const [location, setLocation] = useState<string[]>(locationFilter)
  const [stageInvestment, setStageInvestment] = useState<string[]>(
    stageInvestmentFilter
  )
  const [profile, setProfile] = useState<string[]>(profilesFilter)
  const [sectors, setSectors] = useState<string[]>(sectorsFilter)
  const [dealType, setDealType] = useState<string[]>(dealTypeFilter)
  const [businessModel, setBussinessModel] =
    useState<string[]>(businessModelFilter)
  const [geoCoverage, setGeoCoverage] = useState<string[]>(geoCoverageFilter)
  const [industryVertical, setIndustryVertical] = useState<string[]>(
    industryVerticalFilter
  )
  const [valuationRange, setValuationRange] =
    useState<string[]>(valuationRangeFilter)
  const [investmentStage, setInvestmentStage] = useState<string[]>(
    investmentStageFilter
  )

  useEffect(() => {
    if (open) {
      setFirmName(firmNameFilter)
      setLocation(locationFilter)
      setStageInvestment(stageInvestmentFilter)
      setSectors(sectorsFilter)
      setProfile(profilesFilter)
      setDealType(dealTypeFilter)
      setBussinessModel(businessModelFilter)
      setGeoCoverage(geoCoverageFilter)
      setIndustryVertical(industryVerticalFilter)
      setInvestmentStage(investmentStageFilter)
      setValuationRange(valuationRangeFilter)
    }
  }, [open])

  const saveFilters = () => {
    dispatchFilterContext({
      firmName,
      location,
      stageInvestment,
      sectors,
      profile,
      dealType,
      businessModel,
      geoCoverage,
      industryVertical,
      valuationRange,
      investmentStage,
    })
    setOpen(false)
  }

  const deleteFilters = () => {
    dispatchFilterContext({
      firmName: [],
      location: [],
      stageInvestment: [],
      sectors: [],
      profile: [],
      dealType: [],
      businessModel: [],
      geoCoverage: [],
      industryVertical: [],
      investmentStage: [],
      valuationRange: [],
    })
    setOpen(false)
  }

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <div className="filter-main-div">
        <h3>Filters</h3>
        <div className="form">
          <div className="filter-row">
            <FormControl fullWidth>
              <InputLabel id="firmName-label">Firm Name</InputLabel>
              <Select
                value={firmName}
                onChange={(event) => setFirmName(event.target.value as any)}
                label="Firm Name"
                labelId="firmName-label"
                variant="outlined"
                multiple
              >
                {contacts
                  .sort(sortByFirmName)
                  .map((elem) => elem.firmName)
                  .filter(
                    (item, i, ar) =>
                      ar.indexOf(item) === i && item && item.length
                  )
                  .map((elem, index) => (
                    <MenuItem key={`${elem}-${index}`} value={elem}>
                      {elem}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <IconButton onClick={() => setFirmName([])}>
              <ClearIcon color="error" />
            </IconButton>
          </div>
          <div className="filter-row">
            <FormControl fullWidth>
              <InputLabel id="location-label">Location</InputLabel>
              <Select
                value={location}
                onChange={(event) => setLocation(event.target.value as any)}
                label="Location"
                labelId="location-label"
                variant="outlined"
                multiple
              >
                {Locations.map((elem) => (
                  <MenuItem value={elem} key={elem}>
                    {elem}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button onClick={() => setLocation(Locations)}>All</Button>
            <IconButton onClick={() => setLocation([])}>
              <ClearIcon color="error" />
            </IconButton>
          </div>
          <div className="filter-row">
            <FormControl fullWidth>
              <InputLabel id="profile-label">Profile</InputLabel>
              <Select
                value={profile}
                onChange={(event) => setProfile(event.target.value as any)}
                label="Profile"
                labelId="profile-label"
                variant="outlined"
                multiple
              >
                {Profiles.map((elem) => (
                  <MenuItem value={elem} key={elem}>
                    {elem}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button onClick={() => setProfile(Profiles)}>All</Button>
            <IconButton onClick={() => setProfile([])}>
              <ClearIcon color="error" />
            </IconButton>
          </div>
          <div className="filter-row">
            <FormControl fullWidth>
              <InputLabel id="businessModel-label">
                Business Model Orientation
              </InputLabel>
              <Select
                value={businessModel}
                onChange={(event) =>
                  setBussinessModel(event.target.value as any)
                }
                label="Business Model Orientation"
                labelId="businessModel-label"
                variant="outlined"
                multiple
              >
                {BusinessModels.map((elem) => (
                  <MenuItem value={elem} key={elem}>
                    {elem}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button onClick={() => setBussinessModel(BusinessModels)}>
              All
            </Button>
            <IconButton onClick={() => setBussinessModel([])}>
              <ClearIcon color="error" />
            </IconButton>
          </div>
          <div className="filter-row">
            <FormControl fullWidth>
              <InputLabel id="geoCoverage-label">Geo Coverage</InputLabel>
              <Select
                value={geoCoverage}
                onChange={(event) => setGeoCoverage(event.target.value as any)}
                label="Geo Coverage"
                labelId="geoCoverage-label"
                variant="outlined"
                multiple
              >
                {GeoCoverages.map((elem) => (
                  <MenuItem value={elem} key={elem}>
                    {elem}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button onClick={() => setGeoCoverage(GeoCoverages)}>All</Button>
            <IconButton onClick={() => setGeoCoverage([])}>
              <ClearIcon color="error" />
            </IconButton>
          </div>
          <div className="filter-row">
            <FormControl fullWidth>
              <InputLabel id="sectors-label">Sectors</InputLabel>
              <Select
                value={sectors}
                onChange={(event) => setSectors(event.target.value as any)}
                label="Sectors"
                labelId="sectors-label"
                variant="outlined"
                multiple
              >
                {Sectors.map((elem) => (
                  <MenuItem value={elem} key={elem}>
                    {elem}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button onClick={() => setSectors(Sectors)}>All</Button>
            <IconButton onClick={() => setSectors([])}>
              <ClearIcon color="error" />
            </IconButton>
          </div>
          <div className="filter-row">
            <FormControl fullWidth>
              <InputLabel id="industryVertical-label">
                Industry Vertical
              </InputLabel>
              <Select
                value={industryVertical}
                onChange={(event) =>
                  setIndustryVertical(event.target.value as any)
                }
                label="Industry Vertical"
                labelId="industryVertical-label"
                variant="outlined"
                multiple
              >
                {IndustryVerticals.map((elem) => (
                  <MenuItem value={elem} key={elem}>
                    {elem}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button onClick={() => setIndustryVertical(IndustryVerticals)}>
              All
            </Button>
            <IconButton onClick={() => setIndustryVertical([])}>
              <ClearIcon color="error" />
            </IconButton>
          </div>
          <div className="filter-row">
            <FormControl fullWidth>
              <InputLabel id="investmentStage-label">
                Investment Stage
              </InputLabel>
              <Select
                value={investmentStage}
                onChange={(event) =>
                  setInvestmentStage(event.target.value as any)
                }
                label="Investment Stage"
                labelId="investmentStage-label"
                variant="outlined"
                multiple
              >
                {InvestmentStages.map((elem) => (
                  <MenuItem value={elem} key={elem}>
                    {elem}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button onClick={() => setInvestmentStage(InvestmentStages)}>
              All
            </Button>
            <IconButton onClick={() => setInvestmentStage([])}>
              <ClearIcon color="error" />
            </IconButton>
          </div>
          <div className="filter-row">
            <FormControl fullWidth>
              <InputLabel id="valuationRange-label">Valuation Range</InputLabel>
              <Select
                value={valuationRange}
                onChange={(event) =>
                  setValuationRange(event.target.value as any)
                }
                label="Valuation Range"
                labelId="valuationRange-label"
                variant="outlined"
                multiple
              >
                {ValuationRanges.map((elem) => (
                  <MenuItem value={elem} key={elem}>
                    {elem}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button onClick={() => setValuationRange(ValuationRanges)}>
              All
            </Button>
            <IconButton onClick={() => setValuationRange([])}>
              <ClearIcon color="error" />
            </IconButton>
          </div>
        </div>
        <div className="order-buttons-div">
          <button onClick={saveFilters}>
            <CheckCircleOutlineIcon className="confirm-icon" />
          </button>
          <button onClick={deleteFilters}>
            <ClearIcon className="delete-icon" />
          </button>
        </div>
      </div>
    </Modal>
  )
}

export const CompanyFiltersModal = ({
  open,
  setOpen,
}: {
  open: boolean
  setOpen: any
}) => {
  const {
    dispatchFilterContext,
    location: locationFilter,
    firmName: firmNameFilter,
    stageInvestment: stageInvestmentFilter,
    sectors: sectorsFilter,
    profile: profilesFilter,
    dealType: dealTypeFilter,
    businessModel: businessModelFilter,
    geoCoverage: geoCoverageFilter,
    industryVertical: industryVerticalFilter,
    investmentStage: investmentStageFilter,
    valuationRange: valuationRangeFilter,
  } = useFilterCompanyContext()
  const [firmName, setFirmName] = useState<string[]>(firmNameFilter)
  const [location, setLocation] = useState<string[]>(locationFilter)
  const [stageInvestment, setStageInvestment] = useState<string[]>(
    stageInvestmentFilter
  )
  const [profile, setProfile] = useState<string[]>(profilesFilter)
  const [sectors, setSectors] = useState<string[]>(sectorsFilter)
  const [dealType, setDealType] = useState<string[]>(dealTypeFilter)
  const [businessModel, setBussinessModel] =
    useState<string[]>(businessModelFilter)
  const [geoCoverage, setGeoCoverage] = useState<string[]>(geoCoverageFilter)
  const [industryVertical, setIndustryVertical] = useState<string[]>(
    industryVerticalFilter
  )
  const [valuationRange, setValuationRange] =
    useState<string[]>(valuationRangeFilter)
  const [investmentStage, setInvestmentStage] = useState<string[]>(
    investmentStageFilter
  )

  useEffect(() => {
    if (open) {
      setFirmName(firmNameFilter)
      setLocation(locationFilter)
      setStageInvestment(stageInvestmentFilter)
      setSectors(sectorsFilter)
      setProfile(profilesFilter)
      setDealType(dealTypeFilter)
      setBussinessModel(businessModelFilter)
      setGeoCoverage(geoCoverageFilter)
      setIndustryVertical(industryVerticalFilter)
      setInvestmentStage(investmentStageFilter)
      setValuationRange(valuationRangeFilter)
    }
  }, [open])

  const saveFilters = () => {
    dispatchFilterContext({
      firmName,
      location,
      stageInvestment,
      sectors,
      profile,
      dealType,
      businessModel,
      geoCoverage,
      industryVertical,
      valuationRange,
      investmentStage,
    })
    setOpen(false)
  }

  const deleteFilters = () => {
    dispatchFilterContext({
      firmName: [],
      location: [],
      stageInvestment: [],
      sectors: [],
      profile: [],
      dealType: [],
      businessModel: [],
      geoCoverage: [],
      industryVertical: [],
      investmentStage: [],
      valuationRange: [],
    })
    setOpen(false)
  }

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <div className="filter-main-div">
        <h3>Filters</h3>
        <div className="form">
          <div className="filter-row">
            <FormControl fullWidth>
              <InputLabel id="location-label">Location</InputLabel>
              <Select
                value={location}
                onChange={(event) => setLocation(event.target.value as any)}
                label="Location"
                labelId="location-label"
                variant="outlined"
                multiple
              >
                {Locations.map((elem) => (
                  <MenuItem value={elem} key={elem}>
                    {elem}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button onClick={() => setLocation(Locations)}>All</Button>
            <IconButton onClick={() => setLocation([])}>
              <ClearIcon color="error" />
            </IconButton>
          </div>
          <div className="filter-row">
            <FormControl fullWidth>
              <InputLabel id="dealType-label">Deal Type</InputLabel>
              <Select
                value={dealType}
                onChange={(event) => setDealType(event.target.value as any)}
                label="Deal Type"
                labelId="dealType-label"
                variant="outlined"
                multiple
              >
                {DealTypes.map((elem) => (
                  <MenuItem value={elem} key={elem}>
                    {elem}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button onClick={() => setDealType(DealTypes)}>All</Button>
            <IconButton onClick={() => setDealType([])}>
              <ClearIcon color="error" />
            </IconButton>
          </div>
          <div className="filter-row">
            <FormControl fullWidth>
              <InputLabel id="businessModel-label">
                Business Model Orientation
              </InputLabel>
              <Select
                value={businessModel}
                onChange={(event) =>
                  setBussinessModel(event.target.value as any)
                }
                label="Business Model Orientation"
                labelId="businessModel-label"
                variant="outlined"
                multiple
              >
                {BusinessModels.map((elem) => (
                  <MenuItem value={elem} key={elem}>
                    {elem}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button onClick={() => setBussinessModel(BusinessModels)}>
              All
            </Button>
            <IconButton onClick={() => setBussinessModel([])}>
              <ClearIcon color="error" />
            </IconButton>
          </div>
          <div className="filter-row">
            <FormControl fullWidth>
              <InputLabel id="sectors-label">Sectors</InputLabel>
              <Select
                value={sectors}
                onChange={(event) => setSectors(event.target.value as any)}
                label="Sectors"
                labelId="sectors-label"
                variant="outlined"
                multiple
              >
                {Sectors.map((elem) => (
                  <MenuItem value={elem} key={elem}>
                    {elem}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button onClick={() => setSectors(Sectors)}>All</Button>
            <IconButton onClick={() => setSectors([])}>
              <ClearIcon color="error" />
            </IconButton>
          </div>
          <div className="filter-row">
            <FormControl fullWidth>
              <InputLabel id="industryVertical-label">
                Industry Vertical
              </InputLabel>
              <Select
                value={industryVertical}
                onChange={(event) =>
                  setIndustryVertical(event.target.value as any)
                }
                label="Industry Vertical"
                labelId="industryVertical-label"
                variant="outlined"
                multiple
              >
                {IndustryVerticals.map((elem) => (
                  <MenuItem value={elem} key={elem}>
                    {elem}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button onClick={() => setIndustryVertical(IndustryVerticals)}>
              All
            </Button>
            <IconButton onClick={() => setIndustryVertical([])}>
              <ClearIcon color="error" />
            </IconButton>
          </div>
          <div className="filter-row">
            <FormControl fullWidth>
              <InputLabel id="investmentStage-label">
                Investment Stage
              </InputLabel>
              <Select
                value={investmentStage}
                onChange={(event) =>
                  setInvestmentStage(event.target.value as any)
                }
                label="Investment Stage"
                labelId="investmentStage-label"
                variant="outlined"
                multiple
              >
                {InvestmentStages.map((elem) => (
                  <MenuItem value={elem} key={elem}>
                    {elem}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button onClick={() => setInvestmentStage(InvestmentStages)}>
              All
            </Button>
            <IconButton onClick={() => setInvestmentStage([])}>
              <ClearIcon color="error" />
            </IconButton>
          </div>
          <div className="filter-row">
            <FormControl fullWidth>
              <InputLabel id="valuationRange-label">Valuation Range</InputLabel>
              <Select
                value={valuationRange}
                onChange={(event) =>
                  setValuationRange(event.target.value as any)
                }
                label="Valuation Range"
                labelId="valuationRange-label"
                variant="outlined"
                multiple
              >
                {ValuationRanges.map((elem) => (
                  <MenuItem value={elem} key={elem}>
                    {elem}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button onClick={() => setValuationRange(ValuationRanges)}>
              All
            </Button>
            <IconButton onClick={() => setValuationRange([])}>
              <ClearIcon color="error" />
            </IconButton>
          </div>
        </div>
        <div className="order-buttons-div">
          <button onClick={saveFilters}>
            <CheckCircleOutlineIcon className="confirm-icon" />
          </button>
          <button onClick={deleteFilters}>
            <ClearIcon className="delete-icon" />
          </button>
        </div>
      </div>
    </Modal>
  )
}
