import { useState } from 'react'
import {
  GridColumns,
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from '@mui/x-data-grid'

import { useCompanyContext } from '../../hooks/context/CompanyContext'
import { useContactContext } from '../../hooks/context/ContactContext'

import { TableTitle } from '../../components/TableTitle/TableTitle'
import { Search } from '../../components/Search/Search'

import { findCompanyById } from '../../utils/findCompany'
import { findContactById } from '../../utils/findContact'
import { sortByCompany } from '../../utils/sortByValuation'
import { numberWithCommas } from '../../utils/formaters'

import './Targets.css'

function CustomToolbar({ fileName }: { fileName: string }) {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport
        printOptions={{ disableToolbarButton: true }}
        csvOptions={{ fileName }}
      />
    </GridToolbarContainer>
  )
}

export const Targets = ({
  orders,
  title,
}: {
  orders: any[]
  title: string
}) => {
  const { companies } = useCompanyContext()
  const { contacts } = useContactContext()
  const [searchValue, setSearchValue] = useState<string>('')

  const ordersColumns: GridColumns = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: true,
      width: 120,
      renderCell: (param: any) => (
        <b style={{ color: '#707FDD' }}>{param.value}</b>
      ),
      sortComparator: (v1: any, v2: any) => {
        const number1 = parseInt(v1.split('-')[1])
        const number2 = parseInt(v2.split('-')[1])
        return number1 - number2
      },
    },
    {
      field: 'company',
      headerName: 'Company',
      flex: 1,
      minWidth: 250,
      valueFormatter: (v: any) => `${v.value !== '' ? v.value : '-'}`,
      sortable: true,
    },
    {
      field: 'contact',
      headerName: 'Contact',
      sortable: true,
      valueFormatter: (v: any) => `${v.value !== '' ? v.value : '-'}`,
      flex: 1,
      minWidth: 250,
    },
    {
      field: 'category',
      headerName: 'Profile',
      sortable: true,
      width: 120,
    },
    {
      field: 'estSize',
      headerName: 'Est Size (,000)',
      valueFormatter: (v: any) =>
        `${v.value?.currency.toString() ?? '-'} ${
          numberWithCommas(v.value?.value) ?? '--'
        }`,
      sortable: false,
      width: 180,
    },
    {
      field: 'pricePerShare',
      headerName: 'PPS',
      valueFormatter: (v: any) =>
        `${v.value?.currency.toString() ?? '-'} ${
          numberWithCommas(v.value?.value) ?? '--'
        }`,
      sortable: false,
      width: 180,
    },
    {
      field: 'valuation',
      headerName: 'Valuation (mn)',
      valueFormatter: (v: any) =>
        `${v.value?.currency.toString() ?? '-'} ${
          numberWithCommas(v.value?.value) ?? '--'
        }`,
      sortable: false,
      width: 180,
    },
    {
      field: 'shareClass',
      headerName: 'Share Class',
      valueFormatter: (v: any) => `${v.value !== '' ? v.value : '-'}`,
      sortable: false,
      width: 180,
    },
    {
      field: 'structure',
      headerName: 'Structure',
      valueFormatter: (v: any) => `${v.value !== '' ? v.value : '-'}`,
      sortable: false,
      width: 180,
    },
    {
      field: 'feeStructure',
      headerName: 'Fee Structure',
      valueFormatter: (v: any) => `${v.value !== '' ? v.value : '-'}`,
      sortable: false,
      width: 200,
    },
  ]

  const applySearchValue = (elem: any) => {
    const value = searchValue.toLowerCase()
    if (
      value.length &&
      !elem.company?.toLowerCase()?.includes(value) &&
      !elem.contact?.toLowerCase()?.includes(value) &&
      !elem.comment?.toLowerCase()?.includes(value)
    ) {
      return false
    }

    return true
  }

  return (
    <div className="targets-view-main-div">
      <section>
        <TableTitle title={title} />
        <Search value={searchValue} setValue={setSearchValue} />
        <DataGrid
          sortingOrder={['desc', 'asc']}
          rows={orders
            .map((order) => ({
              ...order,
              company: findCompanyById(order.company, companies)?.name ?? '--',
              contact: findContactById(order.contact, contacts)?.name,
            }))
            .filter(applySearchValue)
            .sort(sortByCompany)}
          columns={ordersColumns}
          disableSelectionOnClick
          pageSize={100}
          checkboxSelection
          components={{
            Toolbar: CustomToolbar,
          }}
          componentsProps={{
            toolbar: {
              fileName: title.includes('Targets') ? 'targets' : 'holdings',
            },
          }}
        />
      </section>
    </div>
  )
}
