import { useState, useEffect } from 'react'
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Modal,
  TextField,
} from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { v4 as uuidv4 } from 'uuid'

import { Profiles } from '../../types/Profiles'
import { Sectors } from '../../types/Sectors'
import { Locations } from '../../types/Locations'
import { BusinessModels } from '../../types/BusinessModels'
import { GeoCoverages } from '../../types/GeoCoverage'
import { ValuationRanges } from '../../types/ValuationRange'
import { InvestmentStages } from '../../types/InvestmentStage'
import { IndustryVerticals } from '../../types/IndustryVertical'

import { useContactContext } from '../../hooks/context/ContactContext'
import { findContactByName, findContactById } from '../../utils/findContact'

import './CreateContactModal.css'

export const CreateContactModal = ({
  open,
  setOpen,
  isEdit,
}: {
  open: boolean
  setOpen: any
  isEdit?: boolean
}) => {
  const { contacts, selectedContact, dispatchContactContext } =
    useContactContext()
  const [contactName, setContactName] = useState('')
  const [firmName, setFirmName] = useState<string>('')
  const [location, setLocation] = useState<string>('')
  const [profile, setProfile] = useState<string[]>([])
  const [comment, setComment] = useState<string>('')
  const [sectors, setSectors] = useState<string[]>([])
  const [businessModel, setBussinessModel] = useState<string[]>([])
  const [geoCoverage, setGeoCoverage] = useState<string[]>([])
  const [investmentStage, setInvestmentStage] = useState<string[]>([])
  const [valuationRange, setValuationRange] = useState<string[]>([])
  const [industryVertical, setIndustryVertical] = useState<string[]>([])

  useEffect(() => {
    if (isEdit && open) {
      const foundContact = findContactById(selectedContact, contacts)
      setContactName(foundContact?.name ?? '')
      setFirmName(foundContact?.firmName ?? '')
      setLocation(foundContact?.location ?? '')
      setProfile(foundContact?.profile ?? [])
      setSectors(foundContact?.sectors ?? [])
      setComment(foundContact?.comment ?? '')
      setBussinessModel(foundContact.businessModel ?? [])
      setGeoCoverage(foundContact.geoCoverage ?? [])
      setInvestmentStage(foundContact.investmentStage ?? [])
      setValuationRange(foundContact.valuationRange ?? [])
      setIndustryVertical(foundContact.industryVertical ?? [])
    } else if (open) {
      setContactName('')
      setFirmName('')
      setLocation('')
      setProfile([])
      setSectors([])
      setComment('')
      setBussinessModel([])
      setGeoCoverage([])
      setInvestmentStage([])
      setIndustryVertical([])
      setValuationRange([])
    }
  }, [open])

  const addContact = () => {
    if (!findContactByName(contactName, contacts)) {
      const newContact = {
        name: contactName,
        firmName,
        location,
        profile,
        comment,
        sectors,
        businessModel,
        geoCoverage,
        id: uuidv4(),
        investmentStage,
        valuationRange,
        industryVertical,
      }
      dispatchContactContext({ contacts: [...contacts, newContact] })
    }
    setOpen(false)
  }

  const editContact = () => {
    const clonedContacts = [...contacts]
    const index = clonedContacts.findIndex(
      (elem: any) => elem.id === selectedContact
    )
    if (index !== -1) {
      clonedContacts[index] = {
        name: contactName,
        firmName,
        location,
        profile,
        id: clonedContacts[index].id,
        comment,
        sectors,
        businessModel,
        geoCoverage,
        investmentStage,
        valuationRange,
        industryVertical,
      }
    }
    dispatchContactContext({ contacts: clonedContacts })
    setOpen(false)
  }

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <div className="create-contact-main-div">
        <h3>Create Contact</h3>
        <div className="form">
          <TextField
            value={contactName}
            onChange={(event) => setContactName(event.target.value as any)}
            id="outlined-basic"
            label="Contact Name"
            variant="outlined"
            fullWidth
          />
          <TextField
            value={firmName}
            onChange={(event) => setFirmName(event.target.value as any)}
            id="outlined-basic"
            label="Firm Name"
            variant="outlined"
            fullWidth
          />
          <FormControl fullWidth>
            <InputLabel id="location-label">Location</InputLabel>
            <Select
              value={location}
              onChange={(event) => setLocation(event.target.value)}
              label="Location"
              labelId="location-label"
              variant="outlined"
            >
              {Locations.map((elem) => (
                <MenuItem value={elem} key={elem}>
                  {elem}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="profile-label">Profile</InputLabel>
            <Select
              value={profile}
              onChange={(event) => setProfile(event.target.value as any)}
              label="Profile"
              labelId="profile-label"
              variant="outlined"
              multiple
            >
              {Profiles.map((elem) => (
                <MenuItem value={elem} key={elem}>
                  {elem}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="sectors-label">Sectors</InputLabel>
            <Select
              value={sectors}
              onChange={(event) => setSectors(event.target.value as any)}
              label="Sectors"
              labelId="sectors-label"
              variant="outlined"
              multiple
            >
              {Sectors.map((elem) => (
                <MenuItem value={elem} key={elem}>
                  {elem}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="businessModel-label">
              Business Model Orientation
            </InputLabel>
            <Select
              value={businessModel}
              onChange={(event) => setBussinessModel(event.target.value as any)}
              label="Business Model Orientation"
              labelId="businessModel-label"
              variant="outlined"
              multiple
            >
              {BusinessModels.map((elem) => (
                <MenuItem value={elem} key={elem}>
                  {elem}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="geoCoverage-label">Geo Coverage</InputLabel>
            <Select
              value={geoCoverage}
              onChange={(event) => setGeoCoverage(event.target.value as any)}
              label="Geo Coverage"
              labelId="geoCoverage-label"
              variant="outlined"
              multiple
            >
              {GeoCoverages.map((elem) => (
                <MenuItem value={elem} key={elem}>
                  {elem}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div>
            <FormControl fullWidth>
              <InputLabel id="valuationRange-label">Valuation Range</InputLabel>
              <Select
                value={valuationRange}
                onChange={(event) =>
                  setValuationRange(event.target.value as any)
                }
                label="Valuation Rnage"
                labelId="valuationRange-label"
                variant="outlined"
                multiple
              >
                {ValuationRanges.map((elem) => (
                  <MenuItem value={elem} key={elem}>
                    {elem}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="investmentStage-label">
                Investment Stage
              </InputLabel>
              <Select
                value={investmentStage}
                onChange={(event) =>
                  setInvestmentStage(event.target.value as any)
                }
                label="Investment Stage"
                labelId="investmentStage-label"
                variant="outlined"
                multiple
              >
                {InvestmentStages.map((elem) => (
                  <MenuItem value={elem} key={elem}>
                    {elem}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <FormControl fullWidth>
            <InputLabel id="industryVertical-label">
              Industry Vertical
            </InputLabel>
            <Select
              value={industryVertical}
              onChange={(event) =>
                setIndustryVertical(event.target.value as any)
              }
              label="Industry Vertical"
              labelId="industryVertical-label"
              variant="outlined"
              multiple
            >
              {IndustryVerticals.map((elem) => (
                <MenuItem value={elem} key={elem}>
                  {elem}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            value={comment}
            onChange={(event) => setComment(event.target.value)}
            label="Comments"
            variant="outlined"
            multiline
            rows={3}
            fullWidth
          />
        </div>
        <button
          disabled={!contactName.length}
          onClick={isEdit ? editContact : addContact}
        >
          <CheckCircleOutlineIcon className="confirm-icon" />
        </button>
      </div>
    </Modal>
  )
}
