import { useState, useEffect } from 'react'
import { Modal, TextField } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'

import { useOrdersContext } from '../../hooks/context/OrdersContext'

import { findOrderById } from '../../utils/findOrder'

import './EditPairOrder.css'

type EditPairOrderProps = {
  open: boolean
  setOpen: any
  orderId: string
}

export const EditPairOrder = ({
  open,
  setOpen,
  orderId,
}: EditPairOrderProps) => {
  const { pairOrders, dispatchOrdersContext } = useOrdersContext()
  const [comment, setComment] = useState('')

  useEffect(() => {
    if (open) {
      const foundOrder = findOrderById(orderId, pairOrders)
      setComment(foundOrder?.comment)
    }
  }, [open])

  // AVOID ERRORS
  if (!open || orderId === '') {
    return null
  }

  const editPairOrder = () => {
    const foundOrder = findOrderById(orderId, pairOrders)
    const clonedPairOrder = [...pairOrders]
    const index = clonedPairOrder.findIndex((elem: any) => elem.id === orderId)
    if (index !== -1) {
      clonedPairOrder[index] = { ...foundOrder, comment }
    }

    dispatchOrdersContext({ pairOrders: clonedPairOrder })
    setOpen(false)
  }

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <div className="edit-pair-main-div">
        <h3>Edit Pair Order</h3>
        <TextField
          value={comment}
          onChange={(event) => setComment(event.target.value)}
          label="Comments"
          variant="outlined"
          multiline
          rows={3}
          fullWidth
        />
        <div className="buttons-div">
          <button onClick={editPairOrder}>
            <CheckCircleOutlineIcon className="confirm-icon" />
          </button>
        </div>
      </div>
    </Modal>
  )
}
