export const findLowestPossible = (list: any[]) => {
  let counter = 0
  const t = true
  while (t) {
    let flag = false
    for (const order of list) {
      if (order.id.includes(counter)) {
        flag = true
      }
    }
    if (!flag) {
      return counter
    }
    counter++
  }
}
