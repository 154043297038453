import { useOrdersContext } from '../../hooks/context/OrdersContext'

import { PairedTable } from '../../components/PairedTable/PairedTable'

import './PairedView.css'

export const PairedView = () => {
  const { pairOrders } = useOrdersContext()

  return (
    <div className="paired-orders-view-main-div">
      <section>
        <PairedTable orders={pairOrders} searchVisible />
      </section>
    </div>
  )
}
