export const DealTypes = [
  'Primary Equity',
  'Secondary Equity',
  'Loan, convertible',
  'Loan, secured',
  'Loan, unsecured / mezz',
  'Loan, share backed',
  'Loan, defaulted / special sit',
  'Buyout / M&A',
  'Real estate / asset sale',
  '-',
]
