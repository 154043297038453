import { useState, useEffect } from 'react'
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Modal,
  TextField,
} from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { v4 as uuidv4 } from 'uuid'

import { useCompanyContext } from '../../hooks/context/CompanyContext'
import { useOrdersContext } from '../../hooks/context/OrdersContext'

import { DealTypes } from '../../types/DealTypes'
import { Sectors } from '../../types/Sectors'
import { Locations } from '../../types/Locations'
import { BusinessModels } from '../../types/BusinessModels'
import { IndustryVerticals } from '../../types/IndustryVertical'
import { ValuationRanges } from '../../types/ValuationRange'
import { InvestmentStages } from '../../types/InvestmentStage'

import { findCompanyByName, findCompanyById } from '../../utils/findCompany'

import './CreateCompanyModal.css'

export const CreateCompanyModal = ({
  open,
  setOpen,
  isEdit,
}: {
  open: boolean
  setOpen: any
  isEdit?: boolean
}) => {
  const { companies, selectedCompany, dispatchCompanyContext } =
    useCompanyContext()
  const { pairOrders, dispatchOrdersContext } = useOrdersContext()
  const [companyName, setCompanyName] = useState<string>('')
  const [location, setLocation] = useState<string>('')
  const [sectors, setSectors] = useState<string[]>([])
  const [dealType, setDealType] = useState<string[]>([])
  const [industryVertical, setIndustryVertical] = useState<string[]>([])
  const [comment, setComment] = useState<string>('')
  const [businessModel, setBussinessModel] = useState<string[]>([])
  const [background, setBackground] = useState<string>('')
  const [investmentStage, setInvestmentStage] = useState<string>('')
  const [valuationRange, setValuationRange] = useState<string>('')

  useEffect(() => {
    if (isEdit && open) {
      const foundCompany = findCompanyById(selectedCompany, companies)
      setCompanyName(foundCompany?.name ?? '')
      setLocation(foundCompany?.location ?? '')
      setSectors(foundCompany?.sectors ?? [])
      setComment(foundCompany?.comment ?? '')
      setDealType(foundCompany?.dealType ?? [])
      setBussinessModel(foundCompany?.businessModel ?? [])
      setBackground(foundCompany?.background ?? '')
      setIndustryVertical(foundCompany?.industryVertical ?? '')
      setInvestmentStage(foundCompany.investmentStage ?? '')
      setValuationRange(foundCompany.valuationRange ?? '')
    } else if (open) {
      setCompanyName('')
      setLocation('')
      setSectors([])
      setComment('')
      setDealType([])
      setBussinessModel([])
      setBackground('')
      setIndustryVertical([])
      setInvestmentStage('')
      setValuationRange('')
    }
  }, [open])

  const addCompany = () => {
    if (!findCompanyByName(companyName, companies)) {
      const newCompany = {
        name: companyName,
        location,
        sectors,
        id: uuidv4(),
        comment,
        dealType,
        businessModel,
        background,
        industryVertical,
        valuationRange,
        investmentStage,
      }
      dispatchCompanyContext({ companies: [...companies, newCompany] })
    }
    setOpen(false)
  }

  const editCompany = () => {
    const clonedCompanies = [...companies]
    const index = clonedCompanies.findIndex(
      (elem: any) => elem.id === selectedCompany
    )
    if (index !== -1) {
      const newPairOrders = pairOrders.map((item) => ({
        ...item,
        id: item.id.replace(clonedCompanies[index].name, companyName),
      }))
      clonedCompanies[index] = {
        name: companyName,
        location,
        sectors,
        id: clonedCompanies[index].id,
        comment,
        dealType,
        businessModel,
        background,
        industryVertical,
        valuationRange,
        investmentStage,
      }
      dispatchOrdersContext({ pairOrders: newPairOrders })
    }
    dispatchCompanyContext({ companies: clonedCompanies })
    setOpen(false)
  }

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <div className="create-company-main-div">
        <h3>Create Company</h3>
        <div className="form">
          <TextField
            value={companyName}
            onChange={(event) => setCompanyName(event.target.value as any)}
            id="outlined-basic"
            label="Company Name"
            variant="outlined"
            fullWidth
          />
          <FormControl fullWidth>
            <InputLabel id="location-label">Location</InputLabel>
            <Select
              value={location}
              onChange={(event) => setLocation(event.target.value)}
              label="Location"
              labelId="location-label"
              variant="outlined"
            >
              {Locations.map((elem) => (
                <MenuItem value={elem} key={elem}>
                  {elem}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="sectors-label">Sectors</InputLabel>
            <Select
              value={sectors}
              onChange={(event) => setSectors(event.target.value as any)}
              label="Sectors"
              labelId="sectors-label"
              variant="outlined"
              multiple
            >
              {Sectors.map((elem) => (
                <MenuItem value={elem} key={elem}>
                  {elem}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="dealType-label">Deal Type</InputLabel>
            <Select
              value={dealType}
              onChange={(event) => setDealType(event.target.value as any)}
              label="Deal Type"
              labelId="dealType-label"
              variant="outlined"
              multiple
            >
              {DealTypes.map((elem) => (
                <MenuItem value={elem} key={elem}>
                  {elem}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="businessModel-label">
              Business Model Orientation
            </InputLabel>
            <Select
              value={businessModel}
              onChange={(event) => setBussinessModel(event.target.value as any)}
              label="Business Model Orientation"
              labelId="businessModel-label"
              variant="outlined"
              multiple
            >
              {BusinessModels.map((elem) => (
                <MenuItem value={elem} key={elem}>
                  {elem}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="industryVertical-label">
              Industry Vertical
            </InputLabel>
            <Select
              value={industryVertical}
              onChange={(event) =>
                setIndustryVertical(event.target.value as any)
              }
              label="Business Model Orientation"
              labelId="industryVertical-label"
              variant="outlined"
              multiple
            >
              {IndustryVerticals.map((elem) => (
                <MenuItem value={elem} key={elem}>
                  {elem}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div>
            <FormControl fullWidth>
              <InputLabel id="valuationRange-label">Valuation Range</InputLabel>
              <Select
                value={valuationRange}
                onChange={(event) =>
                  setValuationRange(event.target.value as any)
                }
                label="Valuation Rnage"
                labelId="valuationRange-label"
                variant="outlined"
              >
                {ValuationRanges.map((elem) => (
                  <MenuItem value={elem} key={elem}>
                    {elem}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="investmentStage-label">
                Investment Stage
              </InputLabel>
              <Select
                value={investmentStage}
                onChange={(event) =>
                  setInvestmentStage(event.target.value as any)
                }
                label="Investment Stage"
                labelId="investmentStage-label"
                variant="outlined"
              >
                {InvestmentStages.map((elem) => (
                  <MenuItem value={elem} key={elem}>
                    {elem}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <TextField
            value={background}
            onChange={(event) => setBackground(event.target.value)}
            label="Background"
            variant="outlined"
            fullWidth
            multiline
            maxRows={3}
          />
          <TextField
            value={comment}
            onChange={(event) => setComment(event.target.value)}
            label="Comments"
            variant="outlined"
            multiline
            rows={3}
            fullWidth
          />
        </div>
        <button
          disabled={!companyName.length}
          onClick={isEdit ? editCompany : addCompany}
        >
          <CheckCircleOutlineIcon className="confirm-icon" />
        </button>
      </div>
    </Modal>
  )
}
