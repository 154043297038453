import { useState } from 'react'
import { IconButton } from '@mui/material'
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from '@mui/x-data-grid'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

import { TableTitle } from '../../components/TableTitle/TableTitle'
import { EditPairOrder } from '../../components/EditPairOrder/EditPairOrder'
import { Search } from '../../components/Search/Search'

import { useContactContext } from '../../hooks/context/ContactContext'
import { useOrdersContext } from '../../hooks/context/OrdersContext'

import { findOrderById } from '../../utils/findOrder'
import { findContactById } from '../../utils/findContact'
import { sortById } from '../../utils/sortByValuation'
import { removeFromArrayById } from '../../utils/removeFromArray'
import { numberWithCommas } from '../../utils/formaters'

import './PairedTable.css'

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport
        printOptions={{ disableToolbarButton: true }}
        csvOptions={{ fileName: 'paired-orders' }}
      />
    </GridToolbarContainer>
  )
}

export const PairedTable = ({
  orders,
  needExport,
  searchVisible,
}: {
  orders: any[]
  needExport?: boolean
  searchVisible?: boolean
}) => {
  const { buyOrders, sellOrders, pairOrders, dispatchOrdersContext } =
    useOrdersContext()
  const { contacts } = useContactContext()

  const [openEditPairOrder, setOpenEditPairOrder] = useState(false)
  const [pairOrderSelected, setPairOrderSelected] = useState<string>('')
  const [searchValue, setSearchValue] = useState<string>('')

  const applySearchValue = (elem: any) => {
    const value = searchValue?.toLowerCase()
    if (
      value?.length &&
      !elem.id?.toLowerCase()?.includes(value) &&
      !elem.contact?.buyOrder?.toLowerCase()?.includes(value) &&
      !elem.contact?.sellOrder?.toLowerCase()?.includes(value) &&
      !elem.comment?.toLowerCase()?.includes(value)
    ) {
      return false
    }

    return true
  }

  const pairedOrderColumns = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: false,
      minWidth: 250,
      flex: 1,
      renderCell: (param: any) => (
        <div>
          <IconButton
            onClick={() => {
              dispatchOrdersContext({
                pairOrders: removeFromArrayById(param.value, pairOrders),
              })
            }}
            style={{ height: 36, width: 36 }}
            size="large"
          >
            <DeleteOutlineIcon color="error" />
          </IconButton>
          <b style={{ color: '#707FDD' }}>{param.value}</b>
        </div>
      ),
    },
    {
      field: 'contact',
      headerName: 'Contact',
      sortable: false,
      minWidth: 180,
      flex: 1,
      renderCell: (param: any) => (
        <div>
          <p>{param.value?.buyOrder ?? '-'}</p>
          <p>{param.value?.sellOrder ?? '-'}</p>
        </div>
      ),
    },
    {
      field: 'category',
      headerName: 'Profile',
      sortable: false,
      width: 120,
      renderCell: (param: any) => (
        <div>
          <p>{param.value?.buyOrder ?? '-'}</p>
          <p>{param.value?.sellOrder ?? '-'}</p>
        </div>
      ),
    },
    {
      field: 'estSize',
      headerName: 'Est Size',
      sortable: false,
      width: 110,
      renderCell: (param: any) => (
        <div>
          <p>
            {param.value?.buyOrder?.currency}{' '}
            {numberWithCommas(param.value?.buyOrder?.value) ?? '-'}
          </p>
          <p>
            {param.value?.sellOrder?.currency}{' '}
            {numberWithCommas(param.value?.sellOrder?.value) ?? '-'}
          </p>
        </div>
      ),
    },
    {
      field: 'pricePerShare',
      headerName: 'PPS',
      sortable: false,
      width: 140,
      renderCell: (param: any) => (
        <div>
          <p>
            {param.value?.buyOrder?.currency}{' '}
            {numberWithCommas(param.value?.buyOrder?.value) ?? '-'}
          </p>
          <p>
            {param.value?.sellOrder?.currency}{' '}
            {numberWithCommas(param.value?.sellOrder?.value) ?? '-'}
          </p>
        </div>
      ),
    },
    {
      field: 'valuation',
      headerName: 'Valuation (mn)',
      sortable: false,
      width: 150,
      renderCell: (param: any) => (
        <div>
          <p>
            {param.value?.buyOrder?.currency}{' '}
            {numberWithCommas(param.value?.buyOrder?.value) ?? '-'}
          </p>
          <p>
            {param.value?.sellOrder?.currency}{' '}
            {numberWithCommas(param.value?.sellOrder?.value) ?? '-'}
          </p>
        </div>
      ),
    },
    {
      field: 'shareClass',
      headerName: 'Share Class',
      sortable: false,
      width: 160,
      renderCell: (param: any) => (
        <div>
          <p>{param.value?.buyOrder ?? '-'}</p>
          <p>{param.value?.sellOrder ?? ''}</p>
        </div>
      ),
    },
    {
      field: 'structure',
      headerName: 'Structure',
      sortable: false,
      width: 160,
      renderCell: (param: any) => (
        <div>
          <p>{param.value?.buyOrder ?? '-'}</p>
          <p>{param.value?.sellOrder ?? '-'}</p>
        </div>
      ),
    },
    {
      field: 'feeStructure',
      headerName: 'Fee Structure',
      sortable: false,
      width: 160,
      renderCell: (param: any) => (
        <div>
          <p>{param.value?.buyOrder ?? '-'}</p>
          <p>{param.value?.sellOrder ?? '-'}</p>
        </div>
      ),
    },
    {
      field: 'comment',
      headerName: 'Comment',
      sortable: false,
      flex: 0.75,
      minWidth: 250,
      renderCell: (param: any) => (
        <div>
          <p>{param.value}</p>
        </div>
      ),
    },
  ]
  return (
    <section className="paired-table-section">
      <TableTitle title="Paired Orders" />
      {searchVisible && (
        <Search value={searchValue} setValue={setSearchValue} />
      )}
      <DataGrid
        sortingOrder={['desc', 'asc']}
        rows={orders
          .map((order) => {
            const sellOrder = findOrderById(order.sellOrder, sellOrders)
            const buyOrder = findOrderById(order.buyOrder, buyOrders)

            return {
              id: order.id,
              contact: {
                sellOrder: findContactById(sellOrder?.contact, contacts)?.name,
                buyOrder: findContactById(buyOrder?.contact, contacts)?.name,
              },
              category: {
                sellOrder: sellOrder?.category,
                buyOrder: buyOrder?.category,
              },
              estSize: {
                sellOrder: sellOrder?.estSize,
                buyOrder: buyOrder?.estSize,
              },
              pricePerShare: {
                sellOrder: sellOrder?.pricePerShare,
                buyOrder: buyOrder?.pricePerShare,
              },
              valuation: {
                sellOrder: sellOrder?.valuation,
                buyOrder: buyOrder?.valuation,
              },
              shareClass: {
                sellOrder: sellOrder?.shareClass,
                buyOrder: buyOrder?.shareClass,
              },
              structure: {
                sellOrder: sellOrder?.structure,
                buyOrder: buyOrder?.structure,
              },
              feeStructure: {
                sellOrder: sellOrder?.feeStructure,
                buyOrder: buyOrder?.feeStructure,
              },
              comment: order.comment,
            }
          })
          .filter(applySearchValue)
          .sort(sortById)}
        columns={pairedOrderColumns}
        disableSelectionOnClick
        pageSize={100}
        checkboxSelection
        components={
          needExport
            ? {
                Toolbar: CustomToolbar,
              }
            : {}
        }
        rowHeight={64}
        onRowDoubleClick={(param) => {
          setPairOrderSelected(param.id as string)
          setOpenEditPairOrder(true)
        }}
      />
      <EditPairOrder
        open={openEditPairOrder}
        setOpen={setOpenEditPairOrder}
        orderId={pairOrderSelected}
      />
    </section>
  )
}
