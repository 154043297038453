import { createContext, useContext, useReducer } from 'react'

const initialState = {
  firmName: [] as any,
  location: [] as any,
  stageInvestment: [] as any,
  sectors: [] as any,
  profile: [] as any,
  dealType: [] as any,
  businessModel: [] as any,
  geoCoverage: [] as any,
  industryVertical: [] as any,
  investmentStage: [] as any,
  valuationRange: [] as any,
}

type TAction = {
  firmName?: string[]
  location?: string[]
  stageInvestment?: string[]
  sectors?: string[]
  profile?: string[]
  dealType?: string[]
  businessModel?: string[]
  geoCoverage?: string[]
  industryVertical?: string[]
  investmentStage?: string[]
  valuationRange?: string[]
}

type TDispatch = Function
type FilterProviderProps = { children: React.ReactNode; options?: any }

interface IState {
  firmName: string[]
  location: string[]
  stageInvestment: string[]
  sectors: string[]
  profile: string[]
  dealType: string[]
  businessModel: string[]
  geoCoverage: string[]
  industryVertical: string[]
  investmentStage: string[]
  valuationRange: string[]
}

interface IContext extends IState {
  dispatchFilterContext: TDispatch
}

const FilterContactContext = createContext<IContext | undefined>(undefined)

function FilterReducer(state = initialState, action: TAction) {
  return { ...state, ...action }
}

function FilterContactContextProvider({
  children,
  options = {},
}: FilterProviderProps) {
  const [stateFilter, dispatchFilterContext] = useReducer(FilterReducer, {
    ...initialState,
    ...options,
  })
  const value = { ...stateFilter, dispatchFilterContext }
  return (
    <FilterContactContext.Provider value={value as any}>
      {children}
    </FilterContactContext.Provider>
  )
}

function useFilterContactContext() {
  const context = useContext(FilterContactContext)
  if (context === undefined) {
    throw new Error(
      'useFilterContext must be used within a FilterContextProvider'
    )
  }
  return context
}

const FilterCompanyContext = createContext<IContext | undefined>(undefined)

function FilterCompanyContextProvider({
  children,
  options = {},
}: FilterProviderProps) {
  const [stateFilter, dispatchFilterContext] = useReducer(FilterReducer, {
    ...initialState,
    ...options,
  })
  const value = { ...stateFilter, dispatchFilterContext }
  return (
    <FilterCompanyContext.Provider value={value as any}>
      {children}
    </FilterCompanyContext.Provider>
  )
}

function useFilterCompanyContext() {
  const context = useContext(FilterCompanyContext)
  if (context === undefined) {
    throw new Error(
      'useFilterContext must be used within a FilterContextProvider'
    )
  }
  return context
}

export {
  FilterCompanyContextProvider,
  useFilterCompanyContext,
  useFilterContactContext,
  FilterContactContextProvider,
}
