import { createContext, useContext, useReducer } from 'react'

const initialState = {
  contacts: [] as any[],
  selectedContact: '',
}

type TAction = {
  contacts?: any[]
  selectedContact?: string
}

type TDispatch = Function
type ContactProviderProps = { children: React.ReactNode; options?: any }

interface IState {
  contacts: any[]
  selectedContact: string
}

interface IContext extends IState {
  dispatchContactContext: TDispatch
}

const ContactContext = createContext<IContext | undefined>(undefined)

function ContactReducer(state = initialState, action: TAction) {
  return { ...state, ...action }
}

function ContactContextProvider({
  children,
  options = {},
}: ContactProviderProps) {
  const [stateContact, dispatchContactContext] = useReducer(ContactReducer, {
    ...initialState,
    ...options,
  })
  const value = { ...stateContact, dispatchContactContext }
  return (
    <ContactContext.Provider value={value as any}>
      {children}
    </ContactContext.Provider>
  )
}

function useContactContext() {
  const context = useContext(ContactContext)
  if (context === undefined) {
    throw new Error(
      'useContactContext must be used within a ContactContextProvider'
    )
  }
  return context
}

export { ContactContextProvider, useContactContext }
