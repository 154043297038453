import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'

import { CompanyContextProvider } from './hooks/context/CompanyContext'
import { OrdersContextProvider } from './hooks/context/OrdersContext'
import { ContactContextProvider } from './hooks/context/ContactContext'
import {
  FilterContactContextProvider,
  FilterCompanyContextProvider,
} from './hooks/context/FiltersContext'

import App from './App'

import './index.css'

ReactDOM.render(
  <StrictMode>
    <FilterContactContextProvider>
      <FilterCompanyContextProvider>
        <CompanyContextProvider>
          <ContactContextProvider>
            <OrdersContextProvider>
              <App />
            </OrdersContextProvider>
          </ContactContextProvider>
        </CompanyContextProvider>
      </FilterCompanyContextProvider>
    </FilterContactContextProvider>
  </StrictMode>,
  document.getElementById('root')
)

reportWebVitals()
