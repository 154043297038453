import { InputAdornment, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

import './Search.css'

export const Search = ({
  value,
  setValue,
}: {
  value: string
  setValue: any
}) => {
  return (
    <div className="search-main-div">
      <TextField
        value={value}
        onChange={(event) => setValue(event.target.value)}
        variant="outlined"
        fullWidth
        className="search-input"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </div>
  )
}
