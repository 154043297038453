export const BusinessModels = [
  'B2B',
  'B2C',
  'C2C',
  'O2O',
  'P2E',
  'SaaS',
  'Mobile app',
  'Online intermediary',
  'Online marketplace',
  'Web 3.0',
  'Hardware',
  'Software',
  'ODM',
  'OEM',
  'Retailer',
  'Services – financial',
  'Services - IT',
  'Services – legal',
  'Services – accounting / consulting',
  'Services - others',
  'Others',
]
