export const GeoCoverages = [
  'Americas - US',
  'Americas - Canada',
  'Americas - Mexico',
  'Americas - Latin America',
  'Europe - UK',
  'Europe - EU Block',
  'Europe - Other',
  'Asia - China',
  'Asia - India',
  'Asia - Japan',
  'Asia - Korea',
  'Asia - SE Asia',
  'Asia - Other',
  'MENA - Israel',
  'MENA - Dubai',
  'MENA - Africa',
  'MENA - Other',
  '-',
]
