import { useState } from 'react'
import { IconButton } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import DeviceHubIcon from '@mui/icons-material/DeviceHub'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'

import { useCompanyContext } from '../../hooks/context/CompanyContext'
import { useContactContext } from '../../hooks/context/ContactContext'
import { useOrdersContext } from '../../hooks/context/OrdersContext'

import { TableTitle } from '../../components/TableTitle/TableTitle'
import { ModalOrder } from '../../components/ModalOrder/ModalOrder'
import { CreatePaiorOrder } from '../../components/CreatePairOrder/CreatePairOrder'
import { CreateCompanyModal } from '../../components/CreateCompanyModal/CreateCompanyModal'
import { PairedTable } from '../../components/PairedTable/PairedTable'

import { findContactById } from '../../utils/findContact'
import {
  removeFromPairOrders,
  removeFromArrayById,
} from '../../utils/removeFromArray'
import { sortByValuation } from '../../utils/sortByValuation'
import { findLowestPossible } from '../../utils/findLowestPossible'
import { numberWithCommas } from '../../utils/formaters'

import './Company.css'

export const Company = () => {
  const { selectedCompany, companies } = useCompanyContext()
  const { contacts } = useContactContext()
  const { buyOrders, sellOrders, pairOrders, dispatchOrdersContext } =
    useOrdersContext()
  const [openCreateBuyOrder, setOpenCreateBuyOrder] = useState(false)
  const [openCreateSellOrder, setOpenCreateSellOrder] = useState(false)
  const [openEditBuyOrder, setOpenEditBuyOrder] = useState(false)
  const [openEditSellOrder, setOpenEditSellOrder] = useState(false)
  const [openCreatePairOrder, setOpenCreatePairOrder] = useState(false)
  const [openEditCompany, setOpenEditCompany] = useState(false)

  // TO ALLOW EDIT
  const [orderSelected, setOrderSelected] = useState<string>('')
  const [pairSelected, setPairSelected] = useState<string>('')

  if (selectedCompany === '') {
    return null
  }

  const company = companies.find((obj) => obj.id === selectedCompany)

  const createOrder = (order: any, type: string) => {
    if (type === 'buy') {
      dispatchOrdersContext({
        buyOrders: [
          ...buyOrders,
          {
            ...order,
            company: selectedCompany,
            id: `B-${findLowestPossible(buyOrders)}`,
          },
        ],
      })
    } else {
      dispatchOrdersContext({
        sellOrders: [
          ...sellOrders,
          {
            ...order,
            company: selectedCompany,
            id: `S-${findLowestPossible(sellOrders)}`,
          },
        ],
      })
    }
    setOpenCreateBuyOrder(false)
    setOpenCreateSellOrder(false)
  }

  const deleteOrder = (order: any, type: string) => {
    if (type === 'buy') {
      dispatchOrdersContext({
        buyOrders: removeFromArrayById(orderSelected, buyOrders),
      })
    } else {
      dispatchOrdersContext({
        sellOrders: removeFromArrayById(orderSelected, sellOrders),
      })
    }
    dispatchOrdersContext({
      pairOrders: removeFromPairOrders(orderSelected, pairOrders),
    })
    setOpenEditBuyOrder(false)
    setOpenEditSellOrder(false)
  }

  const editOrder = (order: any, type: string) => {
    if (type === 'buy') {
      const clonedBuyOrders = [...buyOrders]
      const index = clonedBuyOrders.findIndex(
        (elem: any) => elem.id === orderSelected
      )
      if (index !== -1) {
        clonedBuyOrders[index] = { ...order, id: clonedBuyOrders[index].id }
      }
      dispatchOrdersContext({ buyOrders: clonedBuyOrders })
    } else {
      const clonedSellOrders = [...sellOrders]
      const index = clonedSellOrders.findIndex(
        (elem: any) => elem.id === orderSelected
      )
      if (index !== -1) {
        clonedSellOrders[index] = { ...order, id: clonedSellOrders[index].id }
      }
      dispatchOrdersContext({ sellOrders: clonedSellOrders })
    }
    setOpenEditBuyOrder(false)
    setOpenEditSellOrder(false)
  }

  const companyColumns = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: false,
      width: 120,
      renderCell: (param: any) => (
        <div>
          <IconButton
            onClick={() => {
              setPairSelected(param.value)
              setOpenCreatePairOrder(true)
            }}
            style={{ padding: 4, height: 36, width: 36 }}
            size="large"
          >
            <DeviceHubIcon color="primary" />
          </IconButton>
          <b style={{ color: '#707FDD' }}>{param.value}</b>
        </div>
      ),
    },
    {
      field: 'contact',
      headerName: 'Contact',
      valueFormatter: (v: any) => `${v.value !== '' ? v.value : '-'}`,
      flex: 1,
      minWidth: 250,
    },
    {
      field: 'estSize',
      headerName: 'Est Size (,000)',
      valueFormatter: (v: any) =>
        `${v.value?.currency.toString() ?? '-'} ${
          numberWithCommas(v.value?.value) ?? '-'
        }`,
      sortable: false,
      width: 180,
    },
    {
      field: 'pricePerShare',
      headerName: 'PPS',
      valueFormatter: (v: any) =>
        `${v.value?.currency.toString() ?? '-'} ${
          numberWithCommas(v.value?.value) ?? '-'
        }`,
      sortable: false,
      width: 180,
    },
    {
      field: 'valuation',
      headerName: 'Valuation (mn)',
      valueFormatter: (v: any) =>
        `${v.value?.currency.toString() ?? '-'} ${
          numberWithCommas(v.value?.value) ?? '-'
        }`,
      sortable: false,
      width: 180,
    },
    {
      field: 'shareClass',
      headerName: 'Share Class',
      valueFormatter: (v: any) => `${v.value !== '' ? v.value : '-'}`,
      sortable: false,
      width: 180,
    },
    {
      field: 'structure',
      headerName: 'Structure',
      valueFormatter: (v: any) => `${v.value !== '' ? v.value : '-'}`,
      sortable: false,
      width: 180,
    },
    {
      field: 'feeStructure',
      headerName: 'Fee Structure',
      valueFormatter: (v: any) => `${v.value !== '' ? v.value : '-'}`,
      sortable: false,
      width: 200,
    },
    {
      field: 'comment',
      headerName: 'Comment',
      valueFormatter: (v: any) => `${v.value !== '' ? v.value : '-'}`,
      flex: 0.75,
      minWidth: 250,
    },
  ]

  return (
    <div className="company-view-main-div">
      <div className="company-name-div">
        <h1>{company.name}</h1>
        <IconButton
          onClick={() => setOpenEditCompany(true)}
          style={{ height: 36, width: 36 }}
          size="large"
        >
          <EditOutlinedIcon color="warning" />
        </IconButton>
      </div>
      <div className="text-fields">
        <div className="row">
          <div>
            <h2>Location</h2>
            <p>{!company?.location?.length ? '-' : company.location}</p>
          </div>
        </div>
        <div className="row">
          <div>
            <h2>Sectors</h2>
            <p>
              {!company?.sectors?.length ? '-' : company.sectors?.join(', ')}
            </p>
          </div>
          <div>
            <h2>Deal Type</h2>
            <p>
              {!company?.dealType?.length ? '-' : company.dealType.join(', ')}
            </p>
          </div>
        </div>
        <div className="row">
          <div>
            <h2>Business Model Orientation</h2>
            <p>
              {!company?.businessModel?.length
                ? '-'
                : company.businessModel?.join(', ')}
            </p>
          </div>
          <div>
            <h2>Industry Vertical</h2>
            <p>
              {!company?.industryVertical?.length
                ? '-'
                : company.industryVertical.join(', ')}
            </p>
          </div>
        </div>
        <div className="row">
          <div>
            <h2>Valuation Range</h2>
            <p>
              {!company?.valuationRange?.length ? '-' : company.valuationRange}
            </p>
          </div>
          <div>
            <h2>Investment Stage</h2>
            <p>
              {!company?.investmentStage?.length
                ? '-'
                : company.investmentStage}
            </p>
          </div>
        </div>
        <div>
          <h2>Background</h2>
          <p>{!company?.background?.length ? '-' : company.background}</p>
        </div>
        <div>
          <h2>Comments</h2>
          <p>{!company?.comment?.length ? '-' : company.comment}</p>
        </div>
      </div>
      <section>
        <TableTitle
          title="Buy Orders"
          buttonText="New Buy"
          onPress={() => setOpenCreateBuyOrder(true)}
        />
        <DataGrid
          rows={buyOrders
            .filter((order) => order.company === selectedCompany)
            .map((order) => ({
              ...order,
              contact: findContactById(order.contact, contacts)?.name ?? '--',
            }))
            .sort(sortByValuation)}
          columns={[
            ...companyColumns.slice(0, 2),
            {
              field: 'category',
              headerName: 'Buyer Profile',
              valueFormatter: (v: any) => `${v.value !== '' ? v.value : '-'}`,
              width: 120,
            },
            ...companyColumns.slice(2),
          ]}
          onRowDoubleClick={(param) => {
            setOrderSelected(param.id as string)
            setOpenEditBuyOrder(true)
          }}
          disableSelectionOnClick
          autoPageSize
        />
      </section>
      <section>
        <TableTitle
          title="Sell Orders"
          buttonText="New Sell"
          onPress={() => setOpenCreateSellOrder(true)}
        />
        <DataGrid
          rows={sellOrders
            .filter((order) => order.company === selectedCompany)
            .map((order) => ({
              ...order,
              contact: findContactById(order.contact, contacts)?.name ?? '--',
            }))
            .sort(sortByValuation)}
          columns={[
            ...companyColumns.slice(0, 2),
            {
              field: 'category',
              headerName: 'Seller Profile',
              valueFormatter: (v: any) => `${v.value !== '' ? v.value : '-'}`,
              width: 120,
            },
            ...companyColumns.slice(2),
          ]}
          onRowDoubleClick={(param) => {
            setOrderSelected(param.id as string)
            setOpenEditSellOrder(true)
          }}
          disableSelectionOnClick
          autoPageSize
        />
      </section>
      <section>
        <PairedTable
          orders={pairOrders.filter((order) => order.id.includes(company.name))}
        />
      </section>
      <ModalOrder
        open={openCreateBuyOrder}
        setOpen={setOpenCreateBuyOrder}
        type="Buy"
        onConfirm={(order: any) => createOrder(order, 'buy')}
        onDelete={() => setOpenCreateBuyOrder(false)}
      />
      <ModalOrder
        open={openCreateSellOrder}
        setOpen={setOpenCreateSellOrder}
        type="Sell"
        onConfirm={(order: any) => createOrder(order, 'sell')}
        onDelete={() => setOpenCreateBuyOrder(false)}
      />
      <ModalOrder
        open={openEditBuyOrder}
        setOpen={setOpenEditBuyOrder}
        type="Buy"
        onConfirm={(order: any) => editOrder(order, 'buy')}
        onDelete={(order: any) => deleteOrder(order, 'buy')}
        orderId={orderSelected}
      />
      <ModalOrder
        open={openEditSellOrder}
        setOpen={setOpenEditSellOrder}
        type="Sell"
        onConfirm={(order: any) => editOrder(order, 'sell')}
        onDelete={(order: any) => deleteOrder(order, 'sell')}
        orderId={orderSelected}
      />
      <CreatePaiorOrder
        open={openCreatePairOrder}
        setOpen={setOpenCreatePairOrder}
        orderId={pairSelected}
      />
      <CreateCompanyModal
        open={openEditCompany}
        setOpen={setOpenEditCompany}
        isEdit
      />
    </div>
  )
}
