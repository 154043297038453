export const findCompanyByName = (
  name: string,
  companies: any[]
): any | null => {
  return companies.find(
    (elem) => elem.name.toLowerCase() === name.trim().toLowerCase()
  )
}

export const findCompanyById = (id: string, companies: any[]): any | null => {
  return companies.find((elem) => elem.id === id)
}
