import { useState } from 'react'
import { Button, ButtonGroup } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  Business,
  PersonOutline,
  DeviceHub,
  AddCircleOutline,
} from '@mui/icons-material'
import ModeStandbyIcon from '@mui/icons-material/ModeStandby'
import ClearIcon from '@mui/icons-material/Clear'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import SellIcon from '@mui/icons-material/Sell'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'

import { CreateCompanyModal } from '../../components/CreateCompanyModal/CreateCompanyModal'
import { CreateContactModal } from '../../components/CreateContactModal/CreateContactModal'
import {
  ContactFiltersModal,
  CompanyFiltersModal,
} from '../../components/FiltersModal/FiltersModal'
import { Search } from '../../components/Search/Search'

import { useCompanyContext } from '../../hooks/context/CompanyContext'
import { useContactContext } from '../../hooks/context/ContactContext'
import { useOrdersContext } from '../../hooks/context/OrdersContext'
import {
  useFilterContactContext,
  useFilterCompanyContext,
} from '../../hooks/context/FiltersContext'
import { useConfirm } from 'material-ui-confirm'

import { sortByName, sortByFirmName } from '../../utils/sortByValuation'
import {
  removeCompanyFromOrders,
  removeCompanyFromPairOrders,
  removeFromArrayById,
  removeContactFromOrders,
  removeContactFromTargtes,
} from '../../utils/removeFromArray'

import './Menu.css'

export const Menu = () => {
  const confirm = useConfirm()
  const navigate = useNavigate()
  const locationPath = useLocation()
  const { companies, selectedCompany, dispatchCompanyContext } =
    useCompanyContext()
  const { contacts, selectedContact, dispatchContactContext } =
    useContactContext()
  const {
    buyOrders,
    sellOrders,
    pairOrders,
    targets,
    holdings,
    dispatchOrdersContext,
  } = useOrdersContext()
  const [openCompany, setOpenCompany] = useState(false)
  const [openContact, setOpenContact] = useState(false)
  const [openFilters, setOpenFilters] = useState(false)
  const [contactSort, setSortContactSort] = useState<string>('Name')
  const [priorityFilter, setPriorityFilter] = useState<string>('All')

  // SEARCH
  const [companySearch, setCompanySearch] = useState<string>('')
  const [contactSearch, setContactSearch] = useState<string>('')

  const pathName = locationPath.pathname

  const deleteCompany = (id: string, name: string) => {
    confirm({ description: 'This action is permanent!' })
      .then(() => {
        dispatchOrdersContext({
          buyOrders: removeCompanyFromOrders(id, buyOrders),
          sellOrders: removeCompanyFromOrders(id, sellOrders),
          pairOrders: removeCompanyFromPairOrders(name, pairOrders),
        })
        dispatchCompanyContext({ selectedCompany: '' })
        dispatchCompanyContext({
          companies: removeFromArrayById(id, companies),
        })
      })
      .catch(() => {})
  }

  const deleteContact = (id: string) => {
    confirm({ description: 'This action is permanent!' })
      .then(() => {
        const { newOrders: newBuyOrders, newPairOrders: step1 } =
          removeContactFromOrders(id, buyOrders, pairOrders)
        dispatchOrdersContext({ buyOrders: newBuyOrders })
        const { newOrders: newSellOrders, newPairOrders: step2 } =
          removeContactFromOrders(id, sellOrders, step1)
        dispatchOrdersContext({
          sellOrders: newSellOrders,
          pairOrders: step2,
          holdings: removeContactFromTargtes(id, holdings),
          targets: removeContactFromTargtes(id, targets),
        })
        dispatchContactContext({
          selectedContact: '',
          contacts: removeFromArrayById(id, contacts),
        })
      })
      .catch(() => {})
  }

  const onClickAdd = () => {
    if (pathName === '/company' || pathName === '/') {
      setOpenCompany(true)
    } else if (pathName === '/account') {
      setOpenContact(true)
    }
  }

  const filterContacts = (elem: any) => {
    const {
      firmName,
      location,
      sectors,
      profile,
      businessModel,
      geoCoverage,
      industryVertical,
      investmentStage,
      valuationRange,
    } = useFilterContactContext()
    if (
      firmName.length &&
      !firmName?.some((value) => elem?.firmName?.includes(value))
    ) {
      return false
    }
    if (
      location.length &&
      !location?.some((value) => elem?.location?.includes(value))
    ) {
      return false
    }
    if (
      sectors.length &&
      !sectors?.some((value) => elem?.sectors?.includes(value))
    ) {
      return false
    }
    if (
      profile.length &&
      !profile?.some((value) => elem?.profile?.includes(value))
    ) {
      return false
    }
    if (
      geoCoverage.length &&
      !geoCoverage?.some((value) => elem?.geoCoverage?.includes(value))
    ) {
      return false
    }
    if (
      businessModel.length &&
      !businessModel?.some((value) => elem?.businessModel?.includes(value))
    ) {
      return false
    }
    if (
      industryVertical.length &&
      !industryVertical?.some((value) =>
        elem?.industryVertical?.includes(value)
      )
    ) {
      return false
    }
    if (
      investmentStage.length &&
      !investmentStage?.some((value) => elem?.investmentStage?.includes(value))
    ) {
      return false
    }
    if (
      valuationRange.length &&
      !valuationRange?.some((value) => elem?.valuationRange?.includes(value))
    ) {
      return false
    }

    // SEARCH
    const search = contactSearch.toLowerCase()
    if (
      search.length &&
      !elem.name?.toLowerCase()?.includes(search) &&
      !elem.firmName?.toLowerCase()?.includes(search) &&
      !elem.comment?.toLowerCase()?.includes(search)
    ) {
      return false
    }

    return true
  }

  const filterCompanies = (elem: any) => {
    const {
      stageInvestment,
      location,
      sectors,
      dealType,
      businessModel,
      industryVertical,
      investmentStage,
      valuationRange,
    } = useFilterCompanyContext()
    if (
      location.length &&
      !location?.some((value) => elem?.location?.includes(value))
    ) {
      return false
    }
    if (
      stageInvestment.length &&
      !stageInvestment?.some((value) => elem?.stage?.includes(value))
    ) {
      return false
    }
    if (
      sectors.length &&
      !sectors?.some((value) => elem?.sectors?.includes(value))
    ) {
      return false
    }
    if (
      dealType.length &&
      !dealType?.some((value) => elem?.dealType?.includes(value))
    ) {
      return false
    }
    if (
      businessModel.length &&
      !businessModel?.some((value) => elem?.businessModel?.includes(value))
    ) {
      return false
    }
    if (
      industryVertical.length &&
      !industryVertical?.some((value) =>
        elem?.industryVertical?.includes(value)
      )
    ) {
      return false
    }
    if (
      investmentStage.length &&
      !investmentStage?.some((value) => elem?.investmentStage?.includes(value))
    ) {
      return false
    }
    if (
      valuationRange.length &&
      !valuationRange?.some((value) => elem?.valuationRange?.includes(value))
    ) {
      return false
    }

    // SEARCH
    const search = companySearch.toLowerCase()
    if (
      search.length &&
      !elem.name?.toLowerCase()?.includes(search) &&
      !elem.comment?.toLowerCase()?.includes(search) &&
      !elem.background?.toLowerCase()?.includes(search)
    ) {
      return false
    }

    switch (priorityFilter) {
      case 'Live bids':
        return buyOrders.some((order: any) => order.company === elem.id)
      case 'Live offers':
        return sellOrders.some((order: any) => order.company === elem.id)
      case 'Active names':
        return (
          sellOrders.some((order: any) => order.company === elem.id) &&
          buyOrders.some((order: any) => order.company === elem.id)
        )
    }

    return true
  }

  return (
    <menu>
      <div className="menu-buttons-div">
        <div className="menu-buttons-row">
          <button
            className={
              pathName === '/company' || pathName === '/' ? '' : 'not-selected'
            }
            onClick={() => navigate('/company')}
          >
            <Business />
          </button>
          <button
            className={pathName === '/account' ? '' : 'not-selected'}
            onClick={() => navigate('/account')}
          >
            <PersonOutline />
          </button>
          <button
            className={pathName === '/paired' ? '' : 'not-selected'}
            onClick={() => navigate('/paired')}
          >
            <DeviceHub />
          </button>
        </div>
        <div className="menu-buttons-row">
          <button
            className={pathName === '/buy-orders' ? '' : 'not-selected'}
            onClick={() => navigate('/buy-orders')}
          >
            <ShoppingCartIcon />
          </button>
          <button
            className={pathName === '/sell-orders' ? '' : 'not-selected'}
            onClick={() => navigate('/sell-orders')}
          >
            <SellIcon />
          </button>
          <button
            className={pathName === '/targets' ? '' : 'not-selected'}
            onClick={() => navigate('/targets')}
          >
            <ModeStandbyIcon />
          </button>
          <button
            className={pathName === '/holdings' ? '' : 'not-selected'}
            onClick={() => navigate('/holdings')}
          >
            <FormatListBulletedIcon />
          </button>
        </div>
      </div>
      {(pathName === '/company' ||
        pathName === '/' ||
        pathName === '/account') && (
        <div className="filters-buttons-div">
          {pathName === '/account' && (
            <>
              <div className="sort-button-div">
                <p>Sort:</p>
                <ButtonGroup variant="text">
                  <Button
                    className={
                      contactSort === 'Name' ? '' : 'tab-button-not-selected'
                    }
                    onClick={() => setSortContactSort('Name')}
                  >
                    Name
                  </Button>
                  <Button
                    className={
                      contactSort === 'Firm Name'
                        ? ''
                        : 'tab-button-not-selected'
                    }
                    onClick={() => setSortContactSort('Firm Name')}
                  >
                    Firm Name
                  </Button>
                </ButtonGroup>
              </div>
              <Search value={contactSearch} setValue={setContactSearch} />
            </>
          )}
          {(pathName === '/company' || pathName === '/') && (
            <>
              <div className="sort-button-div">
                <ButtonGroup variant="text">
                  <Button
                    className={
                      priorityFilter === 'All' ? '' : 'tab-button-not-selected'
                    }
                    onClick={() => setPriorityFilter('All')}
                  >
                    All
                  </Button>
                  <Button
                    className={
                      priorityFilter === 'Live bids'
                        ? ''
                        : 'tab-button-not-selected'
                    }
                    onClick={() => setPriorityFilter('Live bids')}
                  >
                    Live bids
                  </Button>
                  <Button
                    className={
                      priorityFilter === 'Live offers'
                        ? ''
                        : 'tab-button-not-selected'
                    }
                    onClick={() => setPriorityFilter('Live offers')}
                  >
                    Live offers
                  </Button>
                  <Button
                    className={
                      priorityFilter === 'Active names'
                        ? ''
                        : 'tab-button-not-selected'
                    }
                    onClick={() => setPriorityFilter('Active names')}
                  >
                    Active names
                  </Button>
                </ButtonGroup>
              </div>
              <Search value={companySearch} setValue={setCompanySearch} />
            </>
          )}
          <button
            className="filter-button"
            onClick={() => setOpenFilters(true)}
          >
            Filters
          </button>
        </div>
      )}
      {(pathName === '/company' || pathName === '/') && (
        <div className="menu-list">
          {companies
            .filter(filterCompanies)
            .sort(sortByName)
            .map((elem) => (
              <div
                className={elem.id === selectedCompany ? 'selected' : ''}
                key={elem.id}
              >
                <button
                  onClick={() =>
                    dispatchCompanyContext({ selectedCompany: elem.id })
                  }
                >
                  {elem.name}
                </button>
                <button onClick={() => deleteCompany(elem.id, elem.name)}>
                  <ClearIcon />
                </button>
              </div>
            ))}
        </div>
      )}
      {pathName === '/account' && (
        <div className="menu-list">
          {contacts
            .filter(filterContacts)
            .sort(contactSort === 'Name' ? sortByName : sortByFirmName)
            .map((elem) => (
              <div
                className={elem.id === selectedContact ? 'selected' : ''}
                key={elem.id}
              >
                <button
                  onClick={() =>
                    dispatchContactContext({ selectedContact: elem.id })
                  }
                >
                  <p>{elem.name}</p>
                  <p className="firm-name">
                    {elem?.firmName?.length ? elem.firmName : '--'}
                  </p>
                </button>
                <button onClick={() => deleteContact(elem.id)}>
                  <ClearIcon />
                </button>
              </div>
            ))}
        </div>
      )}
      {(pathName === '/company' ||
        pathName === '/account' ||
        pathName === '/') && (
        <button onClick={onClickAdd} className="plus-icon">
          <AddCircleOutline />
        </button>
      )}
      <CreateCompanyModal open={openCompany} setOpen={setOpenCompany} />
      <CreateContactModal open={openContact} setOpen={setOpenContact} />
      {pathName === '/account' ? (
        <ContactFiltersModal open={openFilters} setOpen={setOpenFilters} />
      ) : (
        <CompanyFiltersModal open={openFilters} setOpen={setOpenFilters} />
      )}
    </menu>
  )
}
