export const Profiles: string[] = [
  'PE Investor - Early Stage',
  'PE Investor - Mid Stage',
  'PE Investor - Late Stage',
  'PE Investor - Primaries',
  'PE Investor - Secondaries',
  'PE Investor - Private Credit / Special Sit',
  'Lender - banks/financial institutions',
  'Lender - funds',
  'Lender - FO / UHNW',
  'Broker - Institutional',
  'Broker - Individual',
  'SPV / Platform Manager',
  'Others',
  '-',
]
