import { createContext, useContext, useReducer } from 'react'

const initialState = {
  buyOrders: [] as any[],
  sellOrders: [] as any[],
  holdings: [] as any[],
  targets: [] as any[],
  pairOrders: [] as any[],
}

type TAction = {
  buyOrders?: any[]
  sellOrders?: any[]
  holdings?: any[]
  targets?: any[]
  pairOrders?: any[]
}

type TDispatch = Function
type OrdersProviderProps = { children: React.ReactNode; options?: any }

interface IState {
  buyOrders: any[]
  sellOrders: any[]
  holdings: any[]
  targets: any[]
  pairOrders: any[]
}

interface IContext extends IState {
  dispatchOrdersContext: TDispatch
}

const OrdersContext = createContext<IContext | undefined>(undefined)

function OrdersReducer(state = initialState, action: TAction) {
  return { ...state, ...action }
}

function OrdersContextProvider({
  children,
  options = {},
}: OrdersProviderProps) {
  const [stateOrders, dispatchOrdersContext] = useReducer(OrdersReducer, {
    ...initialState,
    ...options,
  })
  const value = { ...stateOrders, dispatchOrdersContext }
  return (
    <OrdersContext.Provider value={value as any}>
      {children}
    </OrdersContext.Provider>
  )
}

function useOrdersContext() {
  const context = useContext(OrdersContext)
  if (context === undefined) {
    throw new Error(
      'useOrdersContext must be used within a OrdersContextProvider'
    )
  }
  return context
}

export { OrdersContextProvider, useOrdersContext }
