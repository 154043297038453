import { createContext, useContext, useReducer } from 'react'

const initialState = {
  companies: [] as any[],
  selectedCompany: '',
}

type TAction = {
  companies?: any[]
  selectedCompany?: string
}

type TDispatch = Function
type CompanyProviderProps = { children: React.ReactNode; options?: any }

interface IState {
  companies: any[]
  selectedCompany: string
}

interface IContext extends IState {
  dispatchCompanyContext: TDispatch
}

const CompanyContext = createContext<IContext | undefined>(undefined)

function CompanyReducer(state = initialState, action: TAction) {
  return { ...state, ...action }
}

function CompanyContextProvider({
  children,
  options = {},
}: CompanyProviderProps) {
  const [stateCompany, dispatchCompanyContext] = useReducer(CompanyReducer, {
    ...initialState,
    ...options,
  })
  const value = { ...stateCompany, dispatchCompanyContext }
  return (
    <CompanyContext.Provider value={value as any}>
      {children}
    </CompanyContext.Provider>
  )
}

function useCompanyContext() {
  const context = useContext(CompanyContext)
  if (context === undefined) {
    throw new Error(
      'useCompanyContext must be used within a CompanyContextProvider'
    )
  }
  return context
}

export { CompanyContextProvider, useCompanyContext }
