import { Button, IconButton } from '@mui/material'

import { useCompanyContext } from '../../hooks/context/CompanyContext'
import { useContactContext } from '../../hooks/context/ContactContext'
import { useOrdersContext } from '../../hooks/context/OrdersContext'

import CloudDownloadIcon from '@mui/icons-material/CloudDownload'

import './Header.css'

export const Header = () => {
  const { companies, dispatchCompanyContext } = useCompanyContext()
  const { contacts, dispatchContactContext } = useContactContext()
  const {
    buyOrders,
    sellOrders,
    pairOrders,
    targets,
    holdings,
    dispatchOrdersContext,
  } = useOrdersContext()

  const handleCapture = ({ target }: { target: any }) => {
    const fileReader = new FileReader()

    fileReader.readAsText(target.files[0])
    fileReader.onload = (e) => {
      const result = JSON.parse((e?.target?.result as any) ?? '{}')
      dispatchCompanyContext({
        companies: result.companies ?? [],
      })
      dispatchContactContext({
        contacts: result.contacts ?? [],
      })
      dispatchOrdersContext({
        buyOrders: result.buyOrders ?? [],
        sellOrders: result.sellOrders ?? [],
        pairOrders: result.pairOrders ?? [],
        holdings: result.holdings ?? [],
        targets: result.targets ?? [],
      })
    }
  }

  const handleDownload = async () => {
    const myData = {
      companies,
      contacts,
      buyOrders,
      sellOrders,
      pairOrders,
      targets,
      holdings,
    }
    const fileName = 'backup'
    const json = JSON.stringify(myData)
    const blob = new Blob([json], { type: 'application/json' })
    const href = await URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = href
    link.download = fileName + '.json'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <nav>
      <div>
        <h1>J2X</h1>
      </div>
      <div>
        <IconButton onClick={handleDownload} className="backup-button">
          <CloudDownloadIcon />
        </IconButton>
        <Button variant="contained" component="label">
          Upload Backup
          <input type="file" onChange={handleCapture} hidden />
        </Button>
      </div>
    </nav>
  )
}
