import { useState } from 'react'
import { IconButton } from '@mui/material'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import LoopIcon from '@mui/icons-material/Loop'

import { DataGrid } from '@mui/x-data-grid'

import { useCompanyContext } from '../../hooks/context/CompanyContext'
import { useContactContext } from '../../hooks/context/ContactContext'
import { useOrdersContext } from '../../hooks/context/OrdersContext'

import { TableTitle } from '../../components/TableTitle/TableTitle'
import { ModalContactOrder } from '../../components/ModalContactOrder/ModalContactOrder'
import { PairedTable } from '../../components/PairedTable/PairedTable'
import { CreateContactModal } from '../../components/CreateContactModal/CreateContactModal'

import { findCompanyById } from '../../utils/findCompany'
import { findOrderById } from '../../utils/findOrder'
import { sortByCompany } from '../../utils/sortByValuation'
import {
  removeFromArrayById,
  removeFromPairOrders,
} from '../../utils/removeFromArray'
import { findLowestPossible } from '../../utils/findLowestPossible'
import { numberWithCommas } from '../../utils/formaters'

import './Account.css'

export const Account = () => {
  const { companies } = useCompanyContext()
  const { contacts, selectedContact } = useContactContext()
  const {
    buyOrders,
    sellOrders,
    holdings,
    targets,
    pairOrders,
    dispatchOrdersContext,
  } = useOrdersContext()
  const [openCreateHolding, setOpenCreateHolding] = useState(false)
  const [openCreateTarget, setOpenCreateTarget] = useState(false)
  const [openEditHolding, setOpenEditHolding] = useState(false)
  const [openEditTarget, setOpenEditTarget] = useState(false)
  const [openEditContact, setOpenEditContact] = useState(false)

  // ORDERS
  const [openCreateBuyOrder, setOpenCreateBuyOrder] = useState(false)
  const [openCreateSellOrder, setOpenCreateSellOrder] = useState(false)
  const [openEditBuyOrder, setOpenEditBuyOrder] = useState(false)
  const [openEditSellOrder, setOpenEditSellOrder] = useState(false)

  const [orderSelected, setOrderSelected] = useState<string>('')

  if (selectedContact === '') {
    return null
  }

  const contact = contacts.find((obj) => obj.id === selectedContact)

  const createHolding = (order: any) => {
    dispatchOrdersContext({
      holdings: [
        ...holdings,
        {
          ...order,
          contact: selectedContact,
          id: `H-${findLowestPossible(holdings)}`,
        },
      ],
    })

    setOpenCreateHolding(false)
  }

  const deleteHolding = () => {
    dispatchOrdersContext({
      holdings: removeFromArrayById(orderSelected, holdings),
    })
    setOpenEditHolding(false)
  }

  const editHolding = (order: any) => {
    const clonedHoldings = [...holdings]
    const index = clonedHoldings.findIndex(
      (elem: any) => elem.id === orderSelected
    )
    if (index !== -1) {
      clonedHoldings[index] = { ...order, id: clonedHoldings[index].id }
    }

    dispatchOrdersContext({ holdings: clonedHoldings })
    setOpenEditHolding(false)
  }

  const createTarget = (order: any) => {
    dispatchOrdersContext({
      targets: [
        ...targets,
        {
          ...order,
          contact: selectedContact,
          id: `T-${findLowestPossible(targets)}`,
        },
      ],
    })

    setOpenCreateTarget(false)
  }

  const deleteTarget = () => {
    dispatchOrdersContext({
      targets: removeFromArrayById(orderSelected, targets),
    })
    setOpenEditTarget(false)
  }

  const editTarget = (order: any) => {
    const clonedTargets = [...targets]
    const index = clonedTargets.findIndex(
      (elem: any) => elem.id === orderSelected
    )
    if (index !== -1) {
      clonedTargets[index] = { ...order, id: clonedTargets[index].id }
    }

    dispatchOrdersContext({ targets: clonedTargets })
    setOpenEditTarget(false)
  }

  // ORDERS
  const createBuyOrder = (order: any) => {
    dispatchOrdersContext({
      buyOrders: [
        ...buyOrders,
        {
          ...order,
          contact: selectedContact,
          id: `B-${findLowestPossible(buyOrders)}`,
        },
      ],
    })

    setOpenCreateBuyOrder(false)
  }
  const deleteBuyOrder = (order: any) => {
    dispatchOrdersContext({
      buyOrders: removeFromArrayById(order.id, buyOrders),
    })
    dispatchOrdersContext({
      pairOrders: removeFromPairOrders(order.id, pairOrders),
    })
    setOpenEditBuyOrder(false)
  }

  const editBuyOrder = (order: any) => {
    const clonedBuyOrders = [...buyOrders]
    const index = clonedBuyOrders.findIndex(
      (elem: any) => elem.id === orderSelected
    )
    if (index !== -1) {
      clonedBuyOrders[index] = { ...order, id: clonedBuyOrders[index].id }
    }

    dispatchOrdersContext({ buyOrders: clonedBuyOrders })
    setOpenEditBuyOrder(false)
  }

  const createSellOrder = (order: any) => {
    dispatchOrdersContext({
      sellOrders: [
        ...sellOrders,
        {
          ...order,
          contact: selectedContact,
          id: `S-${findLowestPossible(sellOrders)}`,
        },
      ],
    })

    setOpenCreateSellOrder(false)
  }

  const deleteSellOrder = (order: any) => {
    dispatchOrdersContext({
      sellOrders: removeFromArrayById(order.id, sellOrders),
    })
    dispatchOrdersContext({
      pairOrders: removeFromPairOrders(order.id, pairOrders),
    })
    setOpenEditSellOrder(false)
  }

  const editSellOrder = (order: any) => {
    const clonedSellOrders = [...sellOrders]
    const index = clonedSellOrders.findIndex(
      (elem: any) => elem.id === orderSelected
    )
    if (index !== -1) {
      clonedSellOrders[index] = { ...order, id: clonedSellOrders[index].id }
    }

    dispatchOrdersContext({ sellOrders: clonedSellOrders })
    setOpenEditSellOrder(false)
  }

  const convertOrder = (orderId: string) => {
    const foundHolding = findOrderById(orderId, holdings)
    if (foundHolding) {
      dispatchOrdersContext({
        holdings: removeFromArrayById(orderId, holdings),
      })
      createSellOrder(foundHolding)
      return
    }
    const foundTarget = findOrderById(orderId, targets)
    if (foundTarget) {
      dispatchOrdersContext({ targets: removeFromArrayById(orderId, targets) })
      createBuyOrder(foundTarget)
      return
    }
    const foundBuyOrder = findOrderById(orderId, buyOrders)
    if (foundBuyOrder) {
      deleteBuyOrder(foundBuyOrder)
      createTarget(foundBuyOrder)
      return
    }
    const foundSellOrder = findOrderById(orderId, sellOrders)
    if (foundSellOrder) {
      deleteSellOrder(foundSellOrder)
      createHolding(foundSellOrder)
    }
  }

  const accountColumns = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: false,
      width: 120,
      renderCell: (param: any) => (
        <div>
          <IconButton onClick={() => convertOrder(param.value)}>
            <LoopIcon />
          </IconButton>
          <b style={{ color: '#707FDD', marginLeft: 4 }}>{param.value}</b>
        </div>
      ),
    },
    {
      field: 'company',
      headerName: 'Company',
      valueFormatter: (v: any) => `${v.value !== '' ? v.value : '-'}`,
      flex: 1,
      minWidth: 250,
    },
    {
      field: 'estSize',
      headerName: 'Est Size (,000)',
      valueFormatter: (v: any) =>
        `${v.value?.currency.toString() ?? '-'} ${
          numberWithCommas(v.value?.value) ?? '-'
        }`,
      sortable: false,
      width: 180,
    },
    {
      field: 'pricePerShare',
      headerName: 'PPS',
      valueFormatter: (v: any) =>
        `${v.value?.currency.toString() ?? '-'} ${
          numberWithCommas(v.value?.value) ?? '-'
        }`,
      sortable: false,
      width: 180,
    },
    {
      field: 'valuation',
      headerName: 'Valuation (mn)',
      valueFormatter: (v: any) =>
        `${v.value?.currency.toString() ?? '-'} ${
          numberWithCommas(v.value?.value) ?? '-'
        }`,
      sortable: false,
      width: 180,
    },
    {
      field: 'shareClass',
      headerName: 'Share Class',
      valueFormatter: (v: any) => `${v.value !== '' ? v.value : '-'}`,
      sortable: false,
      width: 180,
    },
    {
      field: 'structure',
      headerName: 'Structure',
      valueFormatter: (v: any) => `${v.value !== '' ? v.value : '-'}`,
      sortable: false,
      width: 180,
    },
    {
      field: 'feeStructure',
      headerName: 'Fee Structure',
      valueFormatter: (v: any) => `${v.value !== '' ? v.value : '-'}`,
      sortable: false,
      width: 200,
    },
    {
      field: 'comment',
      headerName: 'Comment',
      valueFormatter: (v: any) => `${v.value !== '' ? v.value : '-'}`,
      flex: 0.75,
      minWidth: 300,
    },
  ]

  return (
    <div className="account-view-main-div">
      <div className="account-name-div">
        <h1>{contact.name}</h1>
        <IconButton
          onClick={() => setOpenEditContact(true)}
          style={{ height: 36, width: 36 }}
          size="large"
        >
          <EditOutlinedIcon color="warning" />
        </IconButton>
      </div>
      <div className="text-fields">
        <div className="row">
          <div>
            <h2>Firm Name</h2>
            <p>{!contact?.firmName?.length ? '-' : contact.firmName}</p>
          </div>
          <div>
            <h2>Location</h2>
            <p>{!contact?.location?.length ? '-' : contact.location}</p>
          </div>
        </div>
        <div className="row">
          <div>
            <h2>Profile</h2>
            <p>
              {!contact?.profile?.length ? '-' : contact.profile?.join(', ')}
            </p>
          </div>
          <div>
            <h2>Sectors</h2>
            <p>
              {!contact?.sectors?.length ? '-' : contact.sectors?.join(', ')}
            </p>
          </div>
        </div>
        <div className="row">
          <div>
            <h2>Geo Coverage</h2>
            <p>{!contact?.geoCoverage?.length ? '-' : contact.geoCoverage}</p>
          </div>
          <div>
            <h2>Business Models</h2>
            <p>
              {!contact?.businessModel?.length
                ? '-'
                : contact.businessModel?.join(', ')}
            </p>
          </div>
        </div>
        <div className="row">
          <div>
            <h2>Valuation Range</h2>
            <p>
              {!contact?.valuationRange?.length
                ? '-'
                : contact.valuationRange?.join(', ')}
            </p>
          </div>
          <div>
            <h2>Investment Stage</h2>
            <p>
              {!contact?.investmentStage?.length
                ? '-'
                : contact.investmentStage.join(', ')}
            </p>
          </div>
        </div>
        <div>
          <h2>Industry Vertical</h2>
          <p>
            {!contact?.industryVertical?.length
              ? '-'
              : contact.industryVertical.join(', ')}
          </p>
        </div>
        <div>
          <h2>Comments</h2>
          <p>{!contact?.comment?.length ? '-' : contact.comment}</p>
        </div>
      </div>
      <section>
        <TableTitle
          title="Buy Orders"
          buttonText="New Buy"
          onPress={() => setOpenCreateBuyOrder(true)}
        />
        <DataGrid
          rows={buyOrders
            .filter((order) => order.contact === selectedContact)
            .map((order) => ({
              ...order,
              company: findCompanyById(order.company, companies)?.name ?? '--',
            }))
            .sort(sortByCompany)}
          columns={[
            ...accountColumns.slice(0, 2),
            {
              field: 'category',
              headerName: 'Buyer Profile',
              valueFormatter: (v: any) => `${v.value !== '' ? v.value : '-'}`,
              width: 120,
            },
            ...accountColumns.slice(2),
          ]}
          onRowDoubleClick={(param) => {
            setOrderSelected(param.id as string)
            setOpenEditBuyOrder(true)
          }}
          disableSelectionOnClick
          autoPageSize
        />
      </section>
      <section>
        <TableTitle
          title="Sell Orders"
          buttonText="New Sell"
          onPress={() => setOpenCreateSellOrder(true)}
        />
        <DataGrid
          rows={sellOrders
            .filter((order) => order.contact === selectedContact)
            .map((order) => ({
              ...order,
              company: findCompanyById(order.company, companies)?.name ?? '--',
            }))
            .sort(sortByCompany)}
          columns={[
            ...accountColumns.slice(0, 2),
            {
              field: 'category',
              headerName: 'Seller Profile',
              valueFormatter: (v: any) => `${v.value !== '' ? v.value : '-'}`,
              width: 120,
            },
            ...accountColumns.slice(2),
          ]}
          onRowDoubleClick={(param) => {
            setOrderSelected(param.id as string)
            setOpenEditSellOrder(true)
          }}
          disableSelectionOnClick
          autoPageSize
        />
      </section>
      <section>
        <PairedTable
          orders={pairOrders.filter(
            (pair) =>
              findOrderById(pair.sellOrder, [...buyOrders, ...sellOrders])
                ?.contact === selectedContact ||
              findOrderById(pair.buyOrder, [...buyOrders, ...sellOrders])
                ?.contact === selectedContact
          )}
        />
      </section>
      <section>
        <TableTitle
          title="Acquistion Targets"
          buttonText="New Target"
          onPress={() => setOpenCreateTarget(true)}
        />
        <DataGrid
          rows={targets
            .filter((order) => order.contact === selectedContact)
            .map((order) => ({
              ...order,
              company: findCompanyById(order.company, companies)?.name ?? '--',
            }))
            .sort(sortByCompany)}
          columns={[
            ...accountColumns.slice(0, 2),
            {
              field: 'category',
              headerName: 'Category',
              valueFormatter: (v: any) => `${v.value !== '' ? v.value : '-'}`,
              width: 120,
            },
            ...accountColumns.slice(2),
          ]}
          disableSelectionOnClick
          onRowDoubleClick={(param) => {
            setOrderSelected(param.id as string)
            setOpenEditTarget(true)
          }}
          autoPageSize
        />
      </section>
      <section>
        <TableTitle
          title="Current Holdings"
          buttonText="New Holding"
          onPress={() => setOpenCreateHolding(true)}
        />
        <DataGrid
          rows={holdings
            .filter((order) => order.contact === selectedContact)
            .map((order) => ({
              ...order,
              company: findCompanyById(order.company, companies)?.name ?? '--',
            }))
            .sort(sortByCompany)}
          columns={[
            ...accountColumns.slice(0, 2),
            {
              field: 'category',
              headerName: 'Category',
              valueFormatter: (v: any) => `${v.value !== '' ? v.value : '-'}`,
              width: 120,
            },
            ...accountColumns.slice(2),
          ]}
          disableSelectionOnClick
          onRowDoubleClick={(param) => {
            setOrderSelected(param.id as string)
            setOpenEditHolding(true)
          }}
          autoPageSize
        />
      </section>
      <ModalContactOrder
        open={openCreateHolding}
        setOpen={setOpenCreateHolding}
        type="Holding"
        onConfirm={(order: any) => createHolding(order)}
        onDelete={() => setOpenCreateHolding(false)}
      />
      <ModalContactOrder
        open={openCreateTarget}
        setOpen={setOpenCreateTarget}
        type="Target"
        onConfirm={(order: any) => createTarget(order)}
        onDelete={() => setOpenCreateTarget(false)}
      />
      <ModalContactOrder
        open={openEditHolding}
        setOpen={setOpenEditHolding}
        type="Holding"
        onConfirm={(order: any) => editHolding(order)}
        onDelete={() => deleteHolding()}
        orderId={orderSelected}
      />
      <ModalContactOrder
        open={openEditTarget}
        setOpen={setOpenEditTarget}
        type="Target"
        onConfirm={(order: any) => editTarget(order)}
        onDelete={() => deleteTarget()}
        orderId={orderSelected}
      />
      <ModalContactOrder
        open={openCreateBuyOrder}
        setOpen={setOpenCreateBuyOrder}
        type="Buy"
        onConfirm={(order: any) => createBuyOrder(order)}
        onDelete={() => setOpenCreateBuyOrder(false)}
      />
      <ModalContactOrder
        open={openCreateSellOrder}
        setOpen={setOpenCreateSellOrder}
        type="Sell"
        onConfirm={(order: any) => createSellOrder(order)}
        onDelete={() => setOpenCreateSellOrder(false)}
      />
      <ModalContactOrder
        open={openEditBuyOrder}
        setOpen={setOpenEditBuyOrder}
        type="Buy"
        onConfirm={(order: any) => editBuyOrder(order)}
        onDelete={(order: any) => deleteBuyOrder(order)}
        orderId={orderSelected}
      />
      <ModalContactOrder
        open={openEditSellOrder}
        setOpen={setOpenEditSellOrder}
        type="Sell"
        onConfirm={(order: any) => editSellOrder(order)}
        onDelete={(order: any) => deleteSellOrder(order)}
        orderId={orderSelected}
      />
      <CreateContactModal
        open={openEditContact}
        setOpen={setOpenEditContact}
        isEdit
      />
    </div>
  )
}
